<template>
    <button
        :class="{
            'full-width': fullWidth,
            'secondary': variant === 'secondary',
            'loading': loading,
        }"
        :data-variant="variant"
        :data-loading="loading"
        :data-size="size"
        @click="handleClick"
    >
        <span class="label"><slot /></span>
    </button>
</template>

<script>
export default {
    name: 'HButtonV2',
    props: {
        label: {
            type: String,
            default: 'Continue',
        },
        variant: {
            type: String,
            default: 'primary',
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'medium',
        },
        click: {
            type: Function,
            required: false,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

button {
    display: block;
	@extend %tag;
    position: relative;
    height: 56px;
	border: 1px solid transparent;
	border-radius: 3.5rem;
	background-color: $charcoal;
    color: $white;
    white-space: nowrap;
	padding: 1rem 1.5rem;
    transition: all 0.125s ease-in-out;

	&:hover {
		background-color: $charcoal-hover;
		cursor: pointer;
	}

	&:active {
		background-color: $charcoal-active;
	}

    &:focus {
        outline: 0.25rem solid $gray-400;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }

    &:disabled {
        background-color: $gray-300;
        cursor: default;

        .label {
            color: $gray-600;
        }
    }

    &[data-variant="secondary"] {
        @extend %caption;
        font-weight: 700;
        text-transform: none;
        height: auto;
        padding: 0.55rem 1.5rem;
    }

    &[data-variant="tertiary"] {
        @extend %caption;
        font-weight: 700;
        text-transform: capitalize;
        height: 40px;
        background-color: $powder-blue-100;
        color: $charcoal;
        padding: 0.625rem 1.5rem;

        &:hover {
            background-color: rgba($powder-blue-100, 0.8);
        }

         &:active {
            background-color: $powder-blue-100;
        }
    }

    &[data-variant="tertiary-dark"] {
        @include caption;

        font-weight: 700;
        text-transform: capitalize;
        letter-spacing: 2%;
        height: 40px;
        background-color: rgba($white, 0.5);
        color: $white;
        padding: 0.625rem 1.5rem;

        &:hover {
            background-color: rgba($powder-blue-100, 0.8);
        }

         &:active {
            background-color: $powder-blue-100;
        }
    }

    &[data-variant="text"] {
        @extend %caption;
        font-weight: 700;
        text-transform: capitalize;
        height: auto;
        background-color: transparent;
        color: $powder-blue-200;
        padding: 0;

        &:hover {
            background-color: transparent;
            color: rgba($powder-blue-200, 0.9);
        }

        &:active {
            background-color: transparent;
            color: $powder-blue-200;
        }
    }

    &[data-variant="empty"] {
        @extend %caption;
        font-weight: 700;
        text-transform: capitalize;
        height: 40px;
        background-color: transparent;
        color: $charcoal;
        padding: 0.625rem 1.5rem;

        &:active {
            background-color: transparent;
        }
    }

    &[data-size="large"] {
        @extend %tag;
        position: relative;
        height: 56px;
        border: 1px solid transparent;
        border-radius: 3.5rem;
        padding: 1rem 1.5rem;
        text-transform: uppercase;
    }
}

.secondary {
    @extend %caption;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0.65rem 1.5rem;
}

.text {
    @extend %caption;
    font-weight: 700;
    text-transform: capitalize;
    background-color: transparent;
    color: $powder-blue-200;
    padding: 0;

    &:hover {
        background-color: transparent;
        color: rgba($powder-blue-200, 0.9);
    }

    &:active {
        background-color: transparent;
        color: $powder-blue-200;
    }
}

.full-width {
	width: 100%;
}

button[data-loading="true"] {
	.label {
		visibility: hidden;
	}

	&:after {
		content: "";
        position: absolute;
        top: 0;
		left: 0;
		right: 0;
		bottom: 0;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 2px solid $gray-400;
        border-bottom-color: transparent;
        opacity: 1;
        animation: button-loading-spinner 1s ease infinite;
        margin: auto;
	}

	@keyframes button-loading-spinner {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

</style>
