<template>
    <div class="subscription-modal__success">
        <img src="@/assets/images/subscription/modification_success.svg" />
        <h2 class="subscription-modal__success-title">
            Success!
        </h2>
        <p class="subscription-modal__success-subtitle">
            Your subscription was successfully updated!
        </p>
    </div>
</template>

<script>

export default {
    name: 'SubscriptionSuccessModalSlide',
};

</script>

<style lang="scss">
@import "@/assets/scss/_type.scss";

.subscription-modal {
    &__success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-title {
            @include heading-sm;
            font-weight: 700;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        &-subtitle {
            @include body;
            margin-bottom: 130px;
        }
    }
}
</style>
