<template>
    <div>
        <Nav :section="section" />
        <consultation-hiw
            v-if="section === 'hiw'"
            @onContinue="onContinue"
        />
        <patient-profile-step
            v-if="section === 'profile'"
            :current-intake-template="selectedIntakeTemplate.data"
            @onContinue="onContinue"
        />
        <pharmacy-overview
            v-if="section === 'pharmacy-overview'"
            @onContinue="goToIntake"
        />
        <pharmacy-questions v-if="section === 'pharmacy-questions'" />
        <intake
            v-if="section === 'intake'"
            :current-intake-template="selectedIntakeTemplate.data"
        />
        <ProtocolSelection v-if="section === 'protocol-selection'" />
        <treatment-preferences
            v-if="section === 'product-configure'"
            :current-intake-template="selectedIntakeTemplate.data"
        />
        <treatment-selection v-if="section === 'treatment'" />
        <upload-identification v-if="section === 'identification-step'" />
        <payment-step
            v-if="section === 'checkout'"
            :current-intake-template="selectedIntakeTemplate.data"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Nav from '@/components/Consultation/Nav.vue';
import ConsultationHiw from '@/components/intake/ConsultationHiw.vue';
import Intake from '@/components/intake/Intake.vue';
import PatientProfileStep from '@/components/intake/PatientProfileStep.vue';
import PharmacyOverview from '@/components/intake/PharmacyOverview.vue';
import PharmacyQuestions from '@/components/intake/PharmacyQuestions.vue';
import IntakeTreatmentSelection from '@/components/TreatmentPreferences/IntakeTreatmentSelection.vue';
import TreatmentPreferences from '@/components/TreatmentPreferences/TreatmentPreferences.vue';
import ProtocolSelection from '@/components/TreatmentPreferences/ProtocolSelection.vue';

import { isMedicalProfileEmpty } from '../components/intake/utils/medicalProfileUtils';
import PaymentStep from './PaymentStep';
import UploadIdentification from './UploadIdentification';

export default {
    name: 'IntakeWrapper',
    components: {
        Nav,
        ConsultationHiw,
        PatientProfileStep,
        Intake,
        TreatmentSelection: IntakeTreatmentSelection,
        TreatmentPreferences,
        UploadIdentification,
        PaymentStep,
        PharmacyQuestions,
        PharmacyOverview,
        ProtocolSelection,
    },
    methods: {
        ...mapActions({
            retrieveIntake: 'intake/retrieveIntake',
            getIntakeTemplate: 'intake/getIntakeTemplate',
            check_mailchimp: 'integrations/checkMailchimpIntegration',
        }),
        async onContinue() {
            const intakeInstance = this.currentIntakeInstance.data;
            const { medical_profile: medicalProfile } = this.user;

            if (!intakeInstance) {
                return this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            }

            const step = (this.user.sex === 'female') ? 0 : 1;

            if (isMedicalProfileEmpty(medicalProfile)) {
                await this.$router.push({
                    path: `/consultation/pharmacy-questions/${this.user._id}/${intakeInstance._id}/question/current`,
                    query: { step },
                });
            } else {
                await this.$router.push({
                    path: `/consultation/pharmacy-overview/${this.user._id}/${intakeInstance._id}/question/none`,
                });
            }
        },
        async goToIntake() {
            const intakeInstance = this.currentIntakeInstance.data;

            if (!intakeInstance) {
                return this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            }

            const firstIntakeQuestion = intakeInstance.intake_responses[0];

            await this.$router.push({
                path: `/consultation/intake/${this.user._id}/${intakeInstance._id}/question/${firstIntakeQuestion._id}`,
                query: { step: 0 },
            });
        },
    },
    mounted() {
        if (!this.selectedIntakeTemplate.data) {
            const intakeTemplate = JSON.parse(window.localStorage.getItem('intake_template'));

            if (intakeTemplate?._id) {
                this.getIntakeTemplate({ templateId: intakeTemplate?._id });
            }
        }

    },
    async created() {
        try {
            await this.check_mailchimp();

            const intakeId = this.$route.params.intakeId;

            if (!this.currentIntakeInstance.data || intakeId !== this.currentIntakeInstance.data._id) {
                const payload = { user: this.user._id, _id: intakeId };
                const intake = await this.retrieveIntake(payload);

                await this.getIntakeTemplate({ templateId: intake.intake_template._id });
            }
        } catch (error) {
            console.error('getIntakeError', error);
        }

    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
        }),
        section() {
            return this.$route.params.section;
        },
    },
};

</script>
