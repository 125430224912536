<template>
    <div>
        <checkout-nav v-if="section === 'profile'"></checkout-nav>
        <patient-profile-step
            v-if="section === 'profile'"
            @onContinue="onProfileContinue"
        ></patient-profile-step>
        <consultation-hiw v-if="section === 'lab-visit'" @onContinue="onProfileContinue"></consultation-hiw>
        <ProtocolPreference v-if="section === 'protocol-preference'" />
        <ProductCheckout v-if="section === 'checkout'" />
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import CheckoutNav from '@/components/Checkout/CheckoutNav';
import ConsultationHiw from '@/components/intake/ConsultationHiw.vue';
import PatientProfileStep from '@/components/intake/PatientProfileStep';
import ProductCheckout from '@/components/ProductOnboarding/ProductCheckout';
import ProtocolPreference from '@/components/ProductOnboarding/ProtocolPreference';
import { getProductsStateData } from '@/store/selectors/products';

export default {
    name: 'SignupWrapper',
    components: {
        ConsultationHiw,
        checkoutNav: CheckoutNav,
        PatientProfileStep,
        ProtocolPreference,
        ProductCheckout,
    },
    data() {
        return {
            section: this.$route.params.section,
        };
    },
    watch: {
        '$route.params.section': {
            async handler(section) {
                this.section = section;
                this.$forceUpdate();
            },
            deep: true,
            immediate: true,
        },
        'productTemplateId': {
            async handler(productTemplateId) {
                if (productTemplateId) {
                    await this.getProducts({ product_template: productTemplateId });
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            selectedProduct: (state) => state.productTemplates.selectedProduct,
            products: getProductsStateData,
        }),
        productTemplateId() {
            const lsProduct = JSON.parse(window.localStorage.getItem('zp_product_template'));

            return this.$route.params.productTemplateId || this.selectedProduct?.id || lsProduct?.id;
        },
    },
    methods: {
        ...mapActions({
            getProducts: 'products/getProducts',
        }),
        onProfileContinue() {
            if (!this.productTemplateId) {
                return this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            }

            if (this.products.length > 1) {
                return this.$router.push({ path: `/signup/protocol-preference/${this.productTemplateId}` });
            }

            this.$router.push({ path: `/signup/checkout/${this.productTemplateId}` });
        },
    },
};
</script>
