<template>
    <div class="subscription-page">
        <div>
            <!--if no subscriptions (empty state)>-->
            <div
                v-if="!subscriptions.loading && !subscriptions.data?.length"
                class="patient-portal-empty__container"
            >
                <div class="patient-portal-empty__content">
                    <div class="patient-portal-empty__graphic subscriptions"></div>
                    <div class="patient-portal-empty__title headline-3-slim">
                        No Active Subscriptions
                    </div>
                    <div class="patient-portal-empty__action">
                        <button class="pill-button black-btn" @click="onVisitShop()">
                            Visit Our Shop
                        </button>
                    </div>
                </div>
            </div>

            <!--if there is a subscription-->
            <div v-else>
                <h4 class="headline-3-slim">
                    Subscriptions
                </h4>
                <p>Manage treatments, snooze or cancel your subscription. We make it easy.</p>
                <h-category-select :options="options" :status="option_status" @handleselectedOption="handleselectedOption" />

                <!-- <div v-if="!subscription_list.length"> -->
                <div v-if="!subscription_list.length && option_status !== 'all'">
                    <div class="subscriptions__empty">
                        <ion-icon name="cube-outline"></ion-icon>
                        <p>There's no {{ option_status }} subscriptions.</p>
                    </div>
                </div>

                <div
                    v-for="(subscription, index) in subscription_list"
                    :key="index"
                    class="subscription-container w100"
                >
                    <SubscriptionItem
                        :id="subscription._id"
                        :key="option_status"
                        :subscription-item="subscription"
                        @onSubscriptionCancelRequest="on_cancel_subscription_modal"
                        @onSubscriptionPauseRequest="on_pause_subscription_modal"
                        @onSubscriptionResumeRequest="on_resume_subscription_modal"
                        @onSubscriptionSnoozeRequest="on_snooze_subscription_modal"
                    />
                </div>
            </div>
        </div>

        <subscription-resume-modal
            v-if="activeModal === resume_modal_id"
            :active="activeModal === resume_modal_id"
            :subscription="selected_subscription.subscription"
            :vendor="selected_subscription.vendor"
            @onModalClose="onModalClose"
            @onSuccess="fetchSubscriptions"
        />

        <subscription-cancel-modal
            v-if="activeModal === cancel_modal_id || activeModal === pause_modal_id"
            :active="activeModal === cancel_modal_id || activeModal === pause_modal_id"
            :pause="activeModal === pause_modal_id"
            :subscription="selected_subscription.subscription"
            @closeModal="onModalClose"
            @onSuccess="fetchSubscriptions"
        />

        <subscription-snooze-modal
            v-if="activeModal === snooze_modal_id"
            :subscription="selected_subscription.subscription"
            :vendor="selected_subscription.vendor"
            :active="activeModal === snooze_modal_id"
            @onRescheduleSuccess="fetchSubscriptions"
            @handleClose="onModalClose"
        />
    </div>
</template>

<script>

import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

import SubscriptionCancelModal from '@/components/Dashboard/Modals/SubscriptionCancelModal.vue';
import SubscriptionResumeModal from '@/components/Dashboard/Modals/SubscriptionResumeModal.vue';
import SubscriptionSnoozeModal from '@/components/Dashboard/Modals/SubscriptionSnoozeModal.vue';
import SubscriptionItem from '@/components/Dashboard/SubscriptionItem.vue';
import HCategorySelect from '@/components/Shared/HCategorySelect.vue';
import HModal from '@/components/Shared/HModal.vue';
import { alerts } from '@/constants/alertEvents';

export default {
    name: 'Subscriptions',
    components: {
        SubscriptionItem,
        SubscriptionCancelModal,
        SubscriptionResumeModal,
        SubscriptionSnoozeModal,
        HCategorySelect,
        HModal,
    },
    data() {
        return {
            loading: false,
            cancel_reason: '',
            selected_subscription: {
                subscription: null,
                vendor: null,
            },
            cancel_modal_id: 'cancel-subscription-modal',
            pause_modal_id: 'pause-subscription-modal',
            resume_modal_id: 'resume-subscription-modal',
            snooze_modal_id: 'snooze-subscription-modal',
            options: [
                {
                    id: 0,
                    label: 'Show All',
                    status: 'all',
                },
                {
                    id: 1,
                    label: 'Active',
                    status: 'active',
                },
                {
                    id: 2,
                    label: 'Paused',
                    status: 'paused',
                },
                {
                    id: 3,
                    label: 'Canceled',
                    status: 'canceled',
                },
            ],
            option_status: 'all',
            subscription_list: [],
            activeModal: null,
        };
    },
    filters: {
        date (date) {
            return moment.unix(date).format('MMMM Do YYYY');

        },
    },
    methods: {
        ...mapActions({
            retrieveSubscriptions: 'user/retrieveSubscriptions',
            getSubscriptions: 'subscriptions/getSubscriptions',
            getSubscription: 'subscriptions/getSubscription',
            cancelSubscription: 'subscriptions/cancelSubscription',
            pauseSubscription: 'subscriptions/pauseSubscription',
            resumeSubscription: 'subscriptions/resumeSubscription',
            createAlert: 'alerts/createAlert',
        }),
        ...mapMutations({
            setButton: 'subscriptions/setButtonText',
        }),
        async fetchSubscriptions() {
            this.loading = true;

            try {
                const subscriptionPayload = {
                    organization: this.userData.organization,
                    patient: this.userData._id,
                };

                await this.getSubscriptions(subscriptionPayload);
                this.subscription_list = this.subscriptions.data;
            } catch (e) {
                console.error('Error fetching subscriptions:', e);
            } finally {
                this.loading = false;
            }
        },
        async on_cancel_subscription_modal(subscription) {
            this.selected_subscription = subscription;
            this.activeModal = subscription ? this.cancel_modal_id : null;
        },
        async on_pause_subscription_modal(subscription) {
            this.selected_subscription = subscription;
            this.activeModal = subscription ? this.pause_modal_id : null;
        },
        async on_resume_subscription_modal(subscription) {
            this.selected_subscription = subscription;
            this.activeModal = this.resume_modal_id;
        },
        async on_snooze_subscription_modal(subscription) {
            this.selected_subscription = subscription;
            this.activeModal = this.snooze_modal_id;
        },
        onModalClose() {
            this.selected_subscription = {
                subscription: null,
                vendor: null,
            };
            this.activeModal = null;
        },
        onVisitShop() {
            this.$router.push({ path: '/shop' });
        },
        async handleselectedOption(option) {
            this.option_status = option.status;

            if (option.status === 'all') {
                this.subscription_list = this.subscriptions.data;
            } else {
                this.subscription_list = this.subscriptions.data.filter((item) => item.status === option.status);
            }
        },
    },
    computed: {
        ...mapState({
            userData: state => state.user.user,
            subscriptions: state => state.subscriptions.subscriptions,
            subscription: state => state.subscriptions.subscription,
        }),
    },
    async created() {
        await this.fetchSubscriptions();
    },
};

</script>

<style lang="scss">
.subscription-page {
    @media (min-width: 1440px) {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }
}

.subscription-modal {
    @media screen and (max-width: 600px) {
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        border-radius: 0 !important;
    }

    &__container {
        height: 100%;
        overflow: auto;
    }
}

.cancel-subscription__modal {

    @media screen and (max-width: 600px) {
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        border-radius: 0 !important;
    }
}

.subscriptions {
    &__empty {
        display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		background-color: #ffffff;
		border: 1px solid #f1f1f1;
		border-radius: 12px;
		padding: 2rem;
		transition: all 0.3s ease;

        p {
            font-family: 'ZP-UI', sans-serif;
            font-size: 0.875rem;
            line-height: 1.5rem;
            padding: 0;
            margin: 0;
        }
    }
}

</style>
