<!-- eslint-disable camelcase -->
<template>
    <div class="addon">
        <transition name="sidebar" mode="out-in">
            <h-sidebar v-if="product_panel" :open="product_panel" @handleClose="closeProductPanel"><product-info-side-panel :product="learn_more_product" :membership="learn_more_membership"></product-info-side-panel></h-sidebar>
        </transition>
        <div class="addon__container">
            <div class="addon__header">
                <h2>Would you like to add any additional treatments?</h2>
                <p >Select any medications you would like to add on to your plan, or skip for now.</p>
            </div>
            <div v-if="addon_products && addon_products.length" class="grid">
                <addon-card
                    v-if="product" v-for="(product, index) in addon_products"
                    :product="product"
                    :checked="product.selected"
                    :dosage="product.selected_configuration ? calculateDosage(product, product.selected_configuration.dosage) : null"
                    :price="product.selected_configuration && `$${product.selected_configuration.pricing.total_discount} every ${product.selected_configuration.shipping.months > 1 ? product.selected_configuration.shipping.months : ''} ${product.selected_configuration.shipping.months > 1 ? 'months' : 'month'}`"
                    :instructions="getInstructions(product)"
                    :defaultFooter="product.selected_configuration && !product.edit_config"
                    :changePlan="canChangePlan(product)"
                    @onCheckbox="onProductClicked(product)"
                    @onLearnMore="onLearnMore(product)"
                    @onChangePlan="onChangePlan(product)"
                >
                    <div v-if="product.selected_configuration && product.edit_config" class="addon-configure">
                        <div>
                            <div v-if="product.dosage_strengths.length > 1">
                            <label class="strong">Dosage Strength</label>
                                <select v-model="product.selected_configuration.dosage" class="protocol__select">
                                    <option v-for="dosage in product.dosage_strengths" :value="dosage">
                                        {{ calculateDosage(product, dosage) }}
                                    </option>
                                </select>
                            </div>
                            <div v-if="product.dosage_frequencies.length > 1">
                                <label class="strong">Frequency</label>
                                <select v-model="product.selected_configuration.frequency" @change="onPlanChange(product)" class="addon__select">
                                    <option v-for="frequency in product.dosage_frequencies" :value="frequency">
                                        {{ frequency.frequency.label }}
                                    </option>
                                </select>
                            </div>
                            <div v-if="product.selected_configuration.frequency.has_custom_quantity">
                                <label class="strong">Quantity</label>
                                <select v-model="product.selected_configuration.quantity"
                                        @change="onPlanChange(product)" class="protocol__select">
                                    <option
                                        v-for="quantity in initializeQuantity(product.selected_configuration.frequency.custom_quantity, product.selected_configuration.shipping)"
                                        :value="quantity * (product.dose_quantity ? product.dose_quantity : 1)">
                                        {{ quantity }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div v-if="product.selected_configuration" class="addon-configure__btn">
                            <h-button v-if="canChangePlan(product)" label="Submit" :handleClick="() => onReturnDetails(product)" variant="primary" full_width large cta/>
                            <h-button v-if="canChangePlan(product)" label="Cancel" :handleClick="() => onReturnDetails(product)" variant="tertiary" full_width large cta/>
                        </div>
                    </div>
                </addon-card>
            </div>
        </div>
        <div class="addon__footer">
            <h-button v-if="default_product" label="Skip" :handleClick="onSkip" variant="tertiary" cta></h-button>
            <div class="addon__btn">
                <h-button
                    label="Continue"
                    :loading="loading"
                    :handle-click="onContinue"
                    variant="primary"
                    large
                    full_width
                    cta
                />
            </div>
        </div>
    </div>
    </template>

    <script>

import axios from 'axios';
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import ClickOutside from 'vue-click-outside';
import vueShowMoreText from 'vue-show-more-text';
import { mapActions, mapMutations, mapState } from 'vuex';

import HButton from '@/components/Shared/HButton';
import HSidebar from '@/components/Shared/HSidebar.vue';
import ProductInfoSidePanel from '@/components/Shared/ProductInfoSidePanel';
import AddonCard from '@/components/TreatmentPreferences/Components/AddonCard';
import { analytics, analyticsActions } from '@/helpers/analytics';
import productService from '@/services/Product';

import { hasImage } from '../../domains/user/lib/checkUserVerification';

    export default {
        name: 'treatment-selection',
        components: {
            vueShowMoreText,
        ProductInfoSidePanel,
        AddonCard,
        HButton,
        HSidebar,
    },
    data() {
        return {
            loading: false,
                selection: '',
                selectedProduct: null,
                custom_quantity: null,
                selected_products: [],
                add_products_error: false,
                default_product: JSON.parse(window.localStorage.getItem('default_item')),
                product_panel: false,
                learn_more_product: null,
                learn_more_membership: null,
                addon_products: []
            }
        },
        async created() {
            try {
            const { data: intakeTemplate } = await axios({
                url: `${process.env.VUE_APP_BASE_END}/intake-template/${this.orgId}/template/${this.$route.query?.intakeTemplateId || this.selectedIntakeTemplate.data._id}`,
                method: 'GET',
            });

            this.intakeTemplate = intakeTemplate;

            await this.onGetProductTemplate();

                this.$forceUpdate();
            } catch (e) {
                console.log('Created: Error', e);
            }
        },
        directives: {
            ClickOutside
        },
        methods: {
            ...mapActions({
                getProductTemplates: 'productTemplates/getManyProductTemplatesByIds',
                getIntakeTemplate: 'intake/getIntakeTemplate',
                getProducts: 'products/getProducts',
                getMembershipPrices: 'memberships/getMembershipPrices',
                updateIntakeProducts: 'intake/updateIntakeProducts',
                getMembership: 'memberships/getMembership',
            }),
            updateTreatment(value) {
                //create a this instance
                let vm = this;
                let params = vm.$router.currentRoute.params;
                const checkoutParams = {
                    section: 'product-configure',
                    intakeId: params.intakeId,
                    productTemplateId: value
                }
                vm.$router.push({name: 'treatment-checkout', params: checkoutParams}).catch(err => console.log(err));
            },
            onProductClicked(product) {
                product.selected = !product.selected;
                this.onProductSelected(product);
                this.$forceUpdate();
            },
            onProductSelected(product) {
                analytics.track(analyticsActions.treatment_preferences_addon_product, {
                    id: product._id,
                    intake_template: product.intake_template,
                    selected: product.selected,
                    name: product.product_name,
                });
                const productIndex = this.selected_products.indexOf(product);
                if (product.selected) {
                    this.selected_products.push(product)
                } else {
                    this.selected_products.splice(productIndex, 1);
                }
                this.$forceUpdate();
            },
            canChangePlan(product) {
                return product.selected_configuration && product.selected_configuration.frequency.has_custom_quantity ||
                    product.dosage_frequencies.length > 1 ||
                    product.dosage_strengths.length > 1
            },
            async onGetProductTemplate() {
            try {
                this.addon_products = await this.getProductTemplates({ ids: this.intakeTemplate.product_addons });
                    await this.setupDefaultOptions();
                    this.$forceUpdate();
                } catch (e) {
                    console.log('Error onGetProductTemplate', e);
            }

            },
            onReturnDetails(product) {
                product.edit_config = false;
                this.$forceUpdate();
            },
            async onLearnMore(product) {
                this.learn_more_product = product;
                if (this.learn_more_product.membership) {
                    await this.getMembership({
                        membership_id: this.learn_more_product.membership
                    });
                    this.learn_more_membership = this.membership.data;
                } else {
                    this.learn_more_membership = null;
                }
                this.product_panel = true;
            },
        closeProductPanel() {

                this.product_panel = false;
            },

        onPlanChange(product) {
            const selectedConfig = product.selected_configuration;
            const { frequency, shipping, membership, products } = selectedConfig;
            const quantity = frequency.has_custom_quantity ? selectedConfig.quantity : frequency.monthly_units;
            const activeProduct = productService.selectProduct(products, { frequency, duration: shipping.months });
            const activeMembership = membership ? membership : false;
            const selectedProduct = this.selected_products.find(selected => product._id === selected._id);

            product.selected_configuration.pricing = productService.generatePrice({
                product: activeProduct,
                frequency,
                membershipPrice: activeMembership,
                units: quantity,
            });

            product.selected_configuration.quantity = quantity;
            analytics.track(analyticsActions.treatment_preferences_addon_change, {
                id: product._id,
                intake_template: product.intake_template,
                name: product.product_name,
                frequency: frequency.frequency.label,
                duration: shipping.months,
                quantity,
            });

            if (selectedProduct) {
                const productIndex = this.selected_products.indexOf(selectedProduct);

                this.selected_products.splice(productIndex, 1);
                this.selected_products.push(product);
            }
            this.$forceUpdate();
        },
            onChangePlan(product) {
                analytics.track(analyticsActions.treatment_preferences_addon_change_plan, {
                    id: product._id,
                    intake_template: product.intake_template,
                    name: product.product_name,
            });

                product.edit_config = true;
                this.$forceUpdate();
            },
            initializeQuantity(customQuantities, duration) {
                let quantities = [];
                if (!duration) {
                    for (let i = customQuantities.min; i <= customQuantities.max; i++) {
                        quantities.push(i);
                    }
                    this.custom_quantity = quantities.find(i => i === customQuantities.default);
                } else {
                    quantities = [];
                    for (let i = customQuantities.min; i <= customQuantities.max; i++) {
                        quantities.push(i);
                    }
                    this.custom_quantity = quantities.find(i => i === customQuantities.default);
                }
                return quantities;
            },
            configureDuration(product) {
                return product.shipping_frequencies.find(duration => duration.months === this.default_product.selected_configuration.shipping.months);
            },
        async setupDefaultOptions() {
            const products = this.addon_products.filter(product => product._id !== this.default_product._id).map(async (product) => {
                const quantity = product.dosage_frequencies[0].custom_quantity ? product.dosage_frequencies[0].custom_quantity.default : product.dosage_frequencies[0].monthly_units;
                const multiplier = product.dose_quantity ? product.dose_quantity : 1;
                const selectedDuration = this.configureDuration(product) ? this.configureDuration(product) : product.shipping_frequencies[0];

                try {
                    const products = await this.getProducts({ product_template: product._id });

                    const activeProduct = productService.selectProduct(products, {
                        frequency: product.dosage_frequencies[0],
                        duration: selectedDuration.months,
                    });

                    let selectedMembership;
                    let membershipPrices;

                    if ('membership' in product) {
                        membershipPrices = await this.getMembershipPrices({ membership: product.membership });
                        selectedMembership = membershipPrices.find(m => m.recurring && m.recurring.interval_count === selectedDuration.months);
                    }

                    const pricing = productService.generatePrice({
                        product: activeProduct,
                        frequency: product.dosage_frequencies[0],
                        membershipPrice: selectedMembership,
                        units: quantity,
                    });

                    product['selected_configuration'] = {
                        frequency: product.dosage_frequencies[0],
                        quantity: quantity * multiplier,
                        shipping: selectedDuration,
                        dosage: product.dosage_strengths[0],
                        pricing,
                        membership_options: membershipPrices,
                        products,
                        edit_config: false,
                        membership: selectedMembership,
                    };

                    return product;
                } catch (e) {
                    console.error('Error setupDefaultOptions', e);
                }
            });

            this.addon_products = await Promise.all(products);
        },
        parseFrequencyInstructions(frequency, product) {
            const instructions = frequency.instructions;

            if (instructions.includes('${dosageWeekly}')) {
                const doseQuantity = product.dose_quantity ? product.dose_quantity : 1;
                const calculateDose = (product.selected_configuration.quantity * doseQuantity * this.parseDose(product.selected_configuration.dosage).dose) / 4;

                return instructions.replace('${dosageWeekly}', calculateDose);
            } else {
                return instructions;
            }
        },
            getInstructions(product) {
                if (product.selected_configuration && product.selected_configuration.frequency.instructions) {
                    return this.parseFrequencyInstructions(product.selected_configuration.frequency, product);
                } else {
                    return product.selected_configuration && product.selected_configuration.frequency.frequency.label;
                }
            },
            parseDose(dosage_strength) {
                return {
                    dose: parseFloat(dosage_strength.strength),
                    suffix: dosage_strength.strength.replace(/[0-9]/g, '').replace(/[^a-zA-Z ]/g, "")
            };
        },
        calculateDosage(product, dosage) {
            const doseQuantity = product.dose_quantity ? product.dose_quantity : 1;
            const doseOptions = this.parseDose(dosage);

            return (doseOptions.dose * doseQuantity) + doseOptions.suffix;
        },
        goToCheckout() {
            let params = this.$router.currentRoute.params;
            const photoType = 'verify_account';

            const defaultParams = {
                section: hasImage(this.user) ? 'checkout' : 'identification-step',
                intakeId: params.intakeId,
                photoType,
            };

            this.$router.push({
                name: 'identification-step-alt',
                query: this.$route.query,
                params: defaultParams,
            });
        },
        async onContinue() {
            this.loading = true;
                let selected_products = JSON.parse(window.localStorage.getItem('selected_products'));

            this.add_products_error = false;

            analytics.track(analyticsActions.treatment_preferences_addon_continue, {
                    products: this.selected_products.length,
                });

            if (this.selected_products.length > 0) {

                this.selected_products.forEach(product => {
                    const selectedConfig = product.selected_configuration;
                    const { frequency, shipping, selected_membership: membership, products } = selectedConfig;
                    const quantity = frequency.has_custom_quantity ? selectedConfig.quantity : frequency.monthly_units;

                    const activeProduct = productService.selectProduct(products, {
                        frequency,
                        duration: shipping.months,
                    });

                    product.selected_configuration.treatment_properties = {
                        // eslint-disable-next-line camelcase
                        duration: shipping.months,
                        // eslint-disable-next-line camelcase
                        has_custom_quantity: frequency.has_custom_quantity,
                        membership_price: membership ? membership.price : null,
                        // eslint-disable-next-line camelcase
                        product: activeProduct._id,
                        product_template: product._id,
                        // eslint-disable-next-line camelcase
                        quantity: quantity * shipping.months,
                        // eslint-disable-next-line camelcase
                        stripe_plan_id: activeProduct.stripe_price_id,
                    };
                    product.selected_configuration.type = 'addon';
                });

                // eslint-disable-next-line camelcase
                let combinedProducts = selected_products.concat(this.selected_products);

                combinedProducts = _.uniqBy(combinedProducts, '_id');
                // eslint-disable-next-line camelcase
                let all_products = JSON.stringify(combinedProducts);

                await this.updateIntakeProducts({
                    selectedProducts: this.selected_products,
                    patient_id: this.user._id,
                });

                window.localStorage.setItem('selected_products', all_products);
                this.goToCheckout();
                this.loading = false;

            } else {
                this.onSkip('continue');
            }
        },
        onSkip(action) {
            if (action !== 'continue') {
                analytics.track(analyticsActions.treatment_preferences_addon_skip);
            }
            this.selected_products = [];
            this.goToCheckout();
            this.loading = false;
            },
        },
        computed: {
            ...mapState({
                userData: state => state.questionnaire.user,
                upsellProductTemplates: state => state.productTemplates.upsellProductTemplates,
                selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
                signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
                user: state => state.user.user,
                membershipPrices: state => state.memberships.prices,
                membership: state => state.memberships.membership,
            orgId: state => state.orgId.data,
        }),
        }
    }

</script>

    <style lang="scss">

    .product-container-bottom {
        padding: 1.5rem;
        background-color: rgb(255, 255, 255);
        flex: 1;
        justify-content: space-between;
        @media(max-width: 760px) {
            padding: 0px .5rem 1rem;
        }
    }

    .product-description-text {
        color: #333333;
        margin: 1.5rem 0 1.5rem;
        font-family: 'ZP-UI', sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }

    .product-select-button {
        outline: none;
        font-family: 'Sofia Medium', sans-serif;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1;
        transition-duration: 100ms;
        transition-property: background, border, box-shadow, color;
        transition-timing-function: ease;
        color: #FFFFFF;
        border: none;
        pointer-events: auto;
        background: #93ba79;
        padding: 15px 0 15px 0;
        display: block;
        min-width: auto;
        width: 100%;
        appearance: none;
        box-shadow: none;
        cursor: pointer;
    }

    .product-select-button:hover {
        color: #fff;
        background: #cc835c;
    }

    </style>
