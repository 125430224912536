<template>
    <div class="hbadge" :class="badgeClasses" data-test="badge" :data-variant="variant">
        <div class="hbadge__dot" :style="{ 'background-color': color }"></div>
        <p class="hbadge__label">
            {{ label }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'HBadge',
    props: {
        label: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: '#ff4c41',
        },
        dark: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: String,
            default: 'default',
        },
        small: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        no_border: {
            type: Boolean,
        },
        variant: {
            type: String,
            default: 'primary',
        },
    },
    computed: {
        badgeClasses() {
            return {
                'dark': this.dark,
                'small': this.small,
                'tiny': this.tiny,
                'no-border': this.no_border,
                'green': this.preset === 'green',
                'blue': this.preset === 'blue',
                'dark-blue': this.preset === 'dark-blue',
                'yellow': this.preset === 'yellow',
                'white': this.preset === 'white',
                'pink': this.preset === 'pink',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.hbadge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    height: 48px;
    border-radius: 3rem;
    border: 1px solid $border-default;
    background-color: $bg-light;
    color: $charcoal;
    padding: 0.75rem 1rem;

    p {
        margin: 0;
    }

    &__dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $white-100;

        &__dark {
            background-color: $charcoal;
        }
    }

    &__label {
        @extend %tag;
    }

    &--small {
        font-size: 0.625rem;
        height: auto;
        padding: 0.4rem 0.8rem;

        .hbadge__label {
            font-size: 0.625rem;
        }
    }

    &[data-variant="secondary"] {
        .hbadge__label {
            @extend %caption;
            font-weight: 700;
            text-transform: none;
        }
    }
}

.dark {
    background-color: $charcoal;
    color: #fff;
    border: none;
}

.blue {
    .hbadge__dot {
        background-color: $powder-blue-100 !important;
    }
}

.dark-blue {
    background-color: $powder-blue-100;
    border: none;

    .hbadge__dot {
        background-color: $charcoal !important;
    }
}

.yellow {
    background-color: $heal-yellow-100;
    color: $heal-yellow-300;
    border: none;

    .hbadge__dot {
        background-color: $heal-yellow-200 !important;
    }
}

.white {
    background-color: $gray-75;
    border: none;

    .hbadge__dot {
        background-color: $stone !important;
    }
}

.pink {
    background-color: $pink-100;
    color: $pink-300;
    border: none;

    .hbadge__dot {
        background-color: $pink-200 !important;
    }
}

.green {
    background-color: $calm-green-100;
    border: none;

    .hbadge__dot {
        background-color: $calm-green-200 !important;
    }

    &.dark {
        background-color: $charcoal;
    }
}

.small {
    height: 32px;
    padding: 0.5rem 0.75rem;

    p {
        font-size: 0.75rem;
    }
}

.tiny {
    max-height: 29px;
    padding: 0.5rem 0.75rem;

    p {
        font-size: 0.625rem;
    }
}

.no-border {
    border: none;
}
</style>
