import { render, staticRenderFns } from "./InfoWell.vue?vue&type=template&id=126a7588"
import script from "./InfoWell.vue?vue&type=script&lang=js"
export * from "./InfoWell.vue?vue&type=script&lang=js"
import style0 from "./InfoWell.vue?vue&type=style&index=0&id=126a7588&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports