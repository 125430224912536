<template>
    <div>
        <ProductNavigation />
        <div class="hcheckout">
            <div class="checkout-details">
                <transition name="alert-fade" mode="out-in">
                    <HAlert
                        v-if="alert.status"
                        class="settings-alert"
                        :message="alert.message"
                        :status="alert.status"
                        @dismiss="closeAlert"
                    />
                </transition>
                <section class="checkout-details__section">
                    <div class="hcheckout__header checkout-details__shipping-action">
                        <div>
                            <h2 data-test="checkout-header">
                                Where should we send your {{ product?.type || 'order' }}?
                            </h2>
                            <p v-if="!existingAddress">
                                Enter your name and address:
                            </p>
                        </div>
                        <div v-if="hasAddress && showAddressForm" class="checkout-details__action link-button" @click="showNewAddress()">
                            Cancel
                        </div>
                    </div>
                    <div v-if="existingAddress" class="checkout-existing">
                        <div class="checkout-existing__address">
                            <img src="@/assets/icons/location.svg" alt="" />
                            <div>
                                <div>{{ user.shipping_info.street_address }}</div>
                                <div>{{ user.shipping_info.city }} {{ user.shipping_info.state }} {{ user.shipping_info.zip_code }}</div>
                            </div>
                        </div>
                        <div class="link-button" @click="showNewAddress(existingAddress)">
                            <span>{{ showAddressForm ? "Cancel" : "Edit" }}</span>
                        </div>
                    </div>
                    <div v-if="showAddressForm" class="checkout-details__shipping">
                        <AutoComplete :shipping="user.shipping_info" :loading="loading" :show_button="false" @shipping-change="onShippingChange" />
                    </div>
                </section>
                <section class="checkout-details__section">
                    <div class="hcheckout__header">
                        <h2>Payment Information</h2>
                        <p>You will not be charged for or shipped any medication until approved by your doctor.</p>
                    </div>
                    <div>
                        <HFSABadge />
                        <div class="checkout-existing checkout-existing__payment">
                            <payment-option
                                ref="elementRef"
                                :has_card="hasCard"
                                :patient-card="patientCard"
                                :payment-error="paymentError"
                                @token="onSignupSubmit"
                                @event="cardChange"
                                @edit-card="cardForm ? cancelCard() : setNewCard()"
                            />
                        </div>
                    </div>
                </section>
                <section v-if="selectedProducts.length" class="checkout-details__section">
                    <CouponCode :products="selectedProducts" />
                </section>
                <div class="mobile-hidden checkout-details__section">
                    <h-button
                        label="Submit"
                        variant="primary"
                        :disabled="buttonDisabled() || isSubmitting"
                        :loading="isSubmitting"
                        :handle-click="onButtonSubmit"
                        cta
                        full_width
                        large
                    />
                </div>
                <div v-if="features" class="protocol__side-benefits mobile-hidden">
                    <Benefits :features="features" />
                </div>
                <div class="checkout-benefits mobile-hidden">
                    <h-Certification />
                </div>
            </div>
            <div class="checkout-summary">
                <div class="hcheckout__header">
                    <h2>Summary</h2>
                </div>
                <div v-if="product" class="checkout-summary__section">
                    <div class="checkout-summary__section-header">
                        Protocol
                    </div>
                    <SummaryItem
                        :product="product"
                        :recurring-duration="selectedPlanPricing.duration"
                        :price="selectedPlanPricing"
                        class="product"
                    />
                    <PriceBreakdown class="price" :price="selectedPlanPricing" />
                </div>

                <div class="desktop-hidden checkout-details__section">
                    <h-button
                        label="Submit"
                        variant="primary"
                        data-test="submit-button"
                        :disabled="buttonDisabled() || isSubmitting"
                        :loading="isSubmitting"
                        :handle-click="onButtonSubmit"
                        cta
                        full_width
                        large
                    />
                </div>
            </div>
            <div v-if="features" class="protocol__side-benefits desktop-hidden">
                <Benefits :features="features" />
            </div>
            <div class="checkout-benefits desktop-hidden">
                <h-Certification />
            </div>
        </div>
    </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import { mapActions, mapGetters, mapState } from 'vuex';

import Benefits from '@/components/Auth/Benefits.vue';
import CouponCode from '@/components/Checkout/CouponCode';
import PaymentOption from '@/components/Checkout/PaymentOption.vue';
import PriceBreakdown from '@/components/Checkout/SubComponents/PriceBreakdown';
import SummaryItem from '@/components/Checkout/SubComponents/SummaryItem';
import AutoComplete from '@/components/intake/AutoComplete';
import ProductNavigation from '@/components/ProductOnboarding/Components/ProductNavigation';
import HAlert from '@/components/Shared/HAlert';
import HButton from '@/components/Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';
import HFSABadge from '@/components/Shared/HFSABadge.vue';
import { tags } from '@/constants/mailchimpTags';
import { PRODUCT_TYPES } from '@/constants/product';
import { getSelectedPlanPricing } from '@/domains/product/utils/getSelectedPlanPricing';
import { hasStripeProfile } from '@/domains/user/lib/checkUserVerification';
import { getShippingInfoByUser } from '@/domains/user/lib/shippingInfo';
import Alert from '@/helpers/alert';
import { analytics, analyticsActions } from '@/helpers/analytics';
import { getCouponDiscountPromoCode, getCouponProductDiscounts } from '@/store/selectors/payment';
import { getProductsProductTemplateStateData, getProductsStateData, getProductSelectedProductId } from '@/store/selectors/products';
import productService from '@/services/Product';

export default {
    name: 'ProductCheckout',
    components: {
        ProductNavigation,
        SummaryItem,
        PriceBreakdown,
        HButton,
        HCertification,
        HFSABadge,
        AutoComplete,
        PaymentOption,
        Benefits,
        HAlert,
        CouponCode,
    },
    data() {
        return {
            product: null,
            submitButtonStatus: 'Submit',
            isSubmitting: false,
            cardIsValid: false,
            hasStripeAccount: false,
            hasCard: false,
            validNewCard: false,
            pricing: null,
            loading: false,
            shippingInfo: {},
            existingAddress: false,
            showAddressForm: false,
            newCard: false,
            cardForm: false,
            validations: null,
            createdOrderId: null,
            alert: new Alert(),
        };
    },
    async created() {
        analytics.track(analyticsActions.payment_opened);
        this.$nextTick(async () => {
            this.hasStripeAccount = hasStripeProfile(this.user);
            this.cardIsValid = this.hasStripeAccount;
            this.newCard = !this.hasStripeAccount;
            this.initializeUser();
            this.initializeProduct();

            await this.getUser({ user: this.user._id });

            try {
                if (this.mailchimpIntegration && this.user.mailchimp_id) {
                    await this.addSubscriberTags({
                        tag: {
                            name: tags.status.checkout.incomplete,
                            status: 'active',
                        },
                        patient: this.user._id,
                    });
                }
            } catch (error) {
                console.error('sendNotification error', error);
            }

            if (this.hasStripeAccount) {
                this.getPatientCard({ user_id: this.user._id })
                    .then(() => {
                        if (this.patientCard) {
                            this.hasCard = true;
                        }
                    })
                    .catch(() => {
                        this.hasStripeAccount = false;
                    });
            }
        });
    },
    beforeDestroy() {
        if (this.$refs.elementRef) {
            this.$refs.elementRef?.destroy?.();
        }
    },
    methods: {
        initializeUser() {
            this.shippingInfo = getShippingInfoByUser(this.user);
            this.existingAddress = this.hasAddress;
            this.showAddressForm = !this.hasAddress;
        },
        showNewAddress(show) {
            this.existingAddress = !show;
            this.showAddressForm = show;
        },
        setNewCard() {
            this.newCard = true;
            this.cardForm = true;
            this.hasStripeAccount = false;
            this.hasCard = false;
            this.validNewCard = false;
        },
        cancelCard() {
            this.newCard = false;
            this.hasCard = true;
            this.cardForm = false;
            this.hasStripeAccount = true;
            this.paymentError.error = false;
            this.$refs.elementRef.getStripeRef().clear();
        },
        updatePricing(pricing) {
            this.pricing = pricing;
        },
        async createStripeAccount(token) {
            if (!this.hasStripeAccount) {
                try {
                    if (this.user.stripe_profile && this.user.stripe_profile.stripe_customer.default_source) {
                        await this.updateCreditCard({
                            token,
                            charge: false,
                            new_user: false,
                            charge_amount: null,
                            user: this.user,
                        });
                    } else {
                        const stripePayload = {
                            organization: this.user.organization,
                            user_id: this.user._id,
                            token,
                        };

                        if (typeof window.Rewardful !== 'undefined') {
                            stripePayload['referral_id'] = window.Rewardful?.referral;
                            stripePayload['affiliate_token'] = window.Rewardful?.affiliate?.token;
                        }

                        await this.createBillingProfile(stripePayload);
                    }

                    await this.getUser({ user: this.user._id });
                } catch (error) {
                    if ('response' in error) {
                        throw {
                            type: 'PAYMENT',
                            error: error.response.data,
                        };
                    } else {
                        throw error;
                    }
                }
            }
        },
        async sendNotifications() {
            try {
                if (this.mailchimpIntegration && this.user.mailchimp_id) {
                    await this.addSubscriberTags({
                        tag: {
                            name: tags.status.checkout.complete,
                            status: 'active',
                        },
                        patient: this.user._id,
                    });
                    await this.addSubscriberTags({
                        tag: {
                            name: tags.status.checkout.incomplete,
                            status: 'inactive',
                        },
                        patient: this.user._id,
                    });
                }
            } catch (error) {
                console.error('sendNotification error', error);
            }

            this.$gtag.event('conversion', {
                send_to: 'AW-375008541/oC11CL_us_wYEJ3a6LIB',
                currency: 'USD',
                transaction_id: '',
                value: this.selectedPlanPricing.total,
                email: this.user.email,
            });

            window.fbq('track', 'Purchase', { currency: 'USD', value: this.selectedPlanPricing.total });
        },

        submit() {
            this.$refs.elementRef.getStripeRef().submit();
        },

        cardChange(event) {
            this.validNewCard = Boolean(event.complete);

            if (event.error !== undefined) {
                this.paymentError.error = true;
                this.paymentError.error_msg = event.error.message;
            } else {
                this.paymentError.error = false;
                this.submitButtonStatus = 'Submit';
            }
        },

        async onSignupSubmit(token) {
            this.paymentError.error = false;
            this.paymentError.error_msg = null;

            if (this.isSubmitting) return;

            this.isSubmitting = true;

            try {
                await this.getUser({ user: this.user._id });

                await this.createStripeAccount(token);
                await this.sendNotifications();

                const selectedProduct = this.product.products.find(product => product._id === this.selectedPlanId);

                if (selectedProduct.pricing.recurring.is_recurring) {
                    await this.createSubscription();
                } else {
                    await this.createOneTimeOrder();
                }

                if (selectedProduct.type === PRODUCT_TYPES.lab && this.createdOrderId) {
                    await this.$router.push({ path: `/lab-instructions/${this.createdOrderId}` });
                } else {
                    await this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
                }

                window.localStorage.removeItem('zp_product_template');

                this.isSubmitting = false;
            } catch (e) {
                this.isSubmitting = false;
                this.submitButtonStatus = 'Error. Try Again.';
                this.paymentError.error = true;

                if ('type' in e) {
                    if (e.type === 'PAYMENT') {
                        this.paymentError.error_msg = e.error.message;
                    }
                    this.$forceUpdate();
                } else {
                    this.paymentError.error_msg = e.message;
                    this.validNewCard = false;
                }
            }
        },

        async onButtonSubmit() {
            if (this.showAddressForm) {
                if (this.validations && this.validations.$invalid) {
                    return;
                }

                const { mobile_number: mobileNumber, ...shippingInfo } = this.shippingInfo;

                try {
                    await this.updateUser({
                        _id: this.user._id,
                        shipping_info: shippingInfo,
                        mobile_number: parsePhoneNumber(mobileNumber || this.user.mobile_number || shippingInfo.phone_number, 'US').nationalNumber,
                    });
                } catch (error) {
                    console.error('updateUser error', error);
                    this.alert.show(error.message, 'critical');
                    throw error;
                }
            }

            if (this.hasCard && this.hasStripeAccount) {
                await this.onSignupSubmit();
            } else {
                this.submit();
            }
        },

        onShippingChange(shipping) {
            this.shippingInfo = shipping.data;
            this.validations = shipping.validations;
        },

        buttonDisabled() {
            const shippingInvalid = this.validations && this.validations.$invalid;
            const paymentInvalid = (this.hasCard && !this.cardIsValid) || (this.newCard && !this.validNewCard);

            return shippingInvalid || paymentInvalid;
        },

        ...mapActions({
            createBillingProfile: 'user/createBillingProfile',
            getUser: 'user/retrieveUser',
            getPatientCard: 'payment/getPatientCard',
            updateCreditCard: 'payment/updateCreditCard',
            addSubscriberTags: 'user/addSubscriberTags',
            updateUser: 'user/updateUser',
            getProductTemplate: 'products/getProductTemplate',
            getProducts: 'products/getProducts',
            createPatientOrder: 'orders/createPatientOrder',
            createSubscriptionByProduct: 'subscriptions/createSubscriptionByProduct',
            submitOrder: 'orders/submitOrder',
        }),

        async initializeProduct() {
            if (this.productId) {
                try {
                    const productTemplate = await this.getProductTemplate({ product_template_id: this.productId });

                    if (!this.products.length && this.selectedProductId === this.productId) {
                        await this.getProducts({ product_template: this.productId });
                    }

                    this.product = {
                        ...productTemplate,
                        products: this.products,
                    };
                } catch (error) {
                    console.error('init product', error);
                }
            }
        },
        async createOneTimeOrder() {
            const order = await this.createPatientOrder({
                organization: this.user.organization,
                data: {
                    name: `${new Date().toLocaleDateString()}_${this.product.name}`.replaceAll('-', '_'),
                    product_template: this.productId,
                    product: this.selectedPlanId,
                    quantity: 1,
                    promo_code: this.couponDiscountName,
                },
            });

            this.createdOrderId = order._id;

            await this.submitOrder({
                organization: this.user.organization,
                order_id: order._id,
            });
        },
        async createSubscription() {
            const subscription = await this.createSubscriptionByProduct({
                organization: this.user.organization,
                productId: this.selectedPlanId,
                promoCode: this.couponDiscountName,
            });

            this.createdOrderId = subscription._id;
        },
        closeAlert() {
            this.alert.dismiss();
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            paymentError: (state) => state.payment.error,
            orgId: (state) => state.orgId,
            patientCard: (state) => state.payment.patientCard.data,
            mailchimpIntegration: (state) => state.integrations.mailchimp_exists.data,
            organizationSettings: (state) => state.organizationSettings,
            products: getProductsStateData,
            productTemplate: getProductsProductTemplateStateData,
            selectedProductId: getProductSelectedProductId,
            couponDiscountName: getCouponDiscountPromoCode,
            specialDiscountByProductId: getCouponProductDiscounts,
        }),
        ...mapGetters({
            hasAddress: 'user/hasAddress',
        }),
        productId() {
            return this.$route.params.productTemplateId;
        },
        selectedPlanId() {
            return this.$route.query.plan || this.products[0]?._id;
        },
        features() {
            return this.organizationSettings.data?.signup?.features;
        },
        planOptions() {
            return productService.getPlanOptions(this.products, this.productTemplate, this.specialDiscountByProductId);
        },
        selectedPlanPricing() {
            return getSelectedPlanPricing(this.planOptions, this.selectedPlanId);
        },
        selectedProducts() {
            return this.product?.products?.map(product => ({ id: product._id })) || [];
        },
    },
};
</script>

<style lang="scss">

.product {
    margin-top: 1.5rem;
}

.price {
    margin-top: 1.5rem;
}

.checkout-alert {
    margin-top: 1rem;
}

</style>
