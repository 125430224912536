<template>
    <div class="hsidebar">
        <div class="hsidebar-panel">
            <div class="hsidebar-panel__top" :class="{'hsidebar-panel__top--justify': badge_label}">
                <div v-if="badge_label">
                    <h-badge :label="badge_label" :preset="badge_preset" :small="badge_size === 'small'" dark />
                </div>
                <button @click="handleClose">
                    <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill="#212121"
                            d="m4.21 4.387.083-.094a1 1 0 0 1 1.32-.083l.094.083L12 10.585l6.293-6.292a1 1 0 1 1 1.414 1.414L13.415 12l6.292 6.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083L12 13.415l-6.293 6.292a1 1 0 0 1-1.414-1.414L10.585 12 4.293 5.707a1 1 0 0 1-.083-1.32l.083-.094-.083.094Z"
                        />
                    </svg>
                </button>
            </div>
            <div class="hsidebar-content" :class="{'padding': !badge_label}">
                <slot></slot>
            </div>
        </div>
        <div class="hsidebar-backdrop" @click="handleClose"></div>
    </div>
</template>

<script>
import HBadge from './HBadge.vue';

export default {
    name: 'HSidebar',
    components: {
        HBadge,
    },
    props: {
        open: {
            type: Boolean,
        },
        badge_label: {
            type: String,
        },
        badge_preset: {
            type: String,
        },
        badge_size: {
            type: String,
        },
    },
    watch: {
        open: {
            immediate: true,
            handler(val) {
                if (val) {
                    document.body.style.overflow = 'hidden';
                }
            },
        },
    },
    methods: {
        handleClose() {
            document.body.style.overflow = 'unset';
            this.$emit('handleClose');
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.hsidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: $z-drawer;
}

.hsidebar-panel {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: $white-100;
    width: 100%;
    padding: 0;
    z-index: $z-drawer;

    &__top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;

        button {
            display: flex;
            flex-direction: row-reverse;
            background: transparent;
            border: none;
            padding: 0;
        }

        &--justify {
            justify-content: space-between;
        }
    }

    @media (min-width: 431px) {
        width: 90%;

        &__top {
            padding: 1.5rem;
        }
    }

    @media (min-width: 640px) {
        width: 80%;
    }

    @media (min-width: 820px) {
        width: 65%;
    }

    @media (min-width: 1024px) {
        width: 60%;

        &__top {
            padding: 2rem;
        }
    }

    @media (min-width: 1280px) {
        width: 50%;
    }
}


.hsidebar-content {
    overflow-y: scroll;
    padding: 1rem 1rem 2rem;
    @extend %helveticaNowText;

    h1 {
        @extend %headingLg;
        margin-left: 0px;
        text-transform: inherit;
    }

    h2 {
        @extend %headingSm;
        text-transform: inherit;
    }

    li p {
        margin-bottom: 0px;
    }

    p {
        @extend %helveticaNowText;
        font-size: 16px;
    }

    @media (min-width: 640px) {
        padding: 1.5rem 1.5rem 2rem;
    }

    @media (min-width: 1024px) {
        padding: 2rem;
    }
}

.hsidebar-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $backdrop;
    z-index: $z-overlay;
    cursor: pointer;
}

.padding {
    padding: 0 2rem;
}
</style>
