<template>
	<div class="well__container info">
        <div class="well__title">{{title}}</div>
        <div class="well__description">{{description}}</div>
        <div class="well__action" @click="on_action()" v-if="has_action">{{action_title}}</div>
    </div>
</template>
<script>
export default {
	name: 'InfoWell',
	components: {},
    props: ['title', 'description', 'action_title', 'has_action'],
	data() {
		return {

		}
	},
	created() {
	},
	computed: {},
	methods: {
        on_action() {
            this.$emit('onAction', true);
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

    .well {

        &__container {
            padding: 2rem;
            border-radius: 12px;
            font-family: 'ZP-UI', sans-serif;
            font-size: 0.875rem;


            &.info {
                background-color: $bg-medium;
            }
        }

        &__title {
            @include caption;
            font-weight: 700;
        }

        &__description {
            @include caption;
            margin-top: 3px;
            margin-bottom: 12px;
        }

        &__action {
            @include caption;
            font-weight: 700;
            cursor: pointer;
            color: $powder-blue-200;
        }
    }
</style>
