<template>
<div>
        <div v-if="pricing" class="checkout-total__container">
            <ul class="checkout-total__breakdown">
                <li class="checkout-total__item">
                    <span class="checkout-total__title">Lab Work</span>
                    <span class="checkout-total__description charcoal">Free</span>
                </li>
                <li class="checkout-total__item">
                    <span class="checkout-total__title">Sub-Total</span>
                    <span v-if="pricing.costs !== pricing.total" class="checkout-total__description">
                        ${{ pricing.costs }}
                    </span>
                </li>
                <li v-if="pricing.specialDiscount" class="checkout-total__item">
                    <span class="checkout-total__title system-green-200">Special Discount</span>
                    <span class="checkout-total__description system-green-200">${{ pricing.specialDiscount }}</span>
                </li>
                <li v-if="pricing.savings" class="checkout-total__item">
                    <span class="checkout-total__title system-green-200">Savings</span>
                    <span class="checkout-total__description system-green-200">${{ pricing.savings }}</span>
                </li>
            </ul>
            <div class="checkout-total__total-item">
                <span class="checkout-total__total">Total</span>
                <span class="checkout-total__total">
                    ${{ pricing.total }}
                </span>
            </div>
        </div>
</div>
</template>
<script>

export default {
    name: 'SummaryTotal',
    props: {
        products: {
            type: Array,
            required: true,
        },
        specialDiscountByProductId: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            pricing: null,
            one_time_pricing: null
        }
    },
    watch: {
        products: 'updatePricing',
        specialDiscountByProductId: 'updatePricing',
    },
    created() {
        this.updatePricing();
    },
    methods: {
        updatePricing() {
            let generatedPricing;

            if (!this.products) {
                const products = JSON.parse(window.localStorage.getItem('selected_products'));

                generatedPricing = this.generatePricing(products || []);
            } else {
                generatedPricing = this.generatePricing(this.products || []);
            }
            this.$emit('onPricingGenerated', generatedPricing);
            this.pricing = generatedPricing;
        },
        generatePricing(selectedProducts) {
            const { total, costs, productSavings, membershipSavings, specialDiscount } = selectedProducts.reduce((acc, productTemplate) => {
                const { shipping, pricing, treatment_properties: treatmentProperties } = productTemplate.selected_configuration;

                if (shipping.non_recurring) return acc;

                acc.total += pricing.total_discount;
                acc.costs += pricing.total;
                acc.productSavings += pricing.product_savings;
                acc.membershipSavings += pricing.membership_savings;

                const specialDiscount = treatmentProperties?.product ? this.specialDiscountByProductId[treatmentProperties.product] : null;

                if (specialDiscount) {
                    acc.specialDiscount += specialDiscount.discount / 100;
                }

                return acc;
            }, { total: 0, costs: 0, productSavings: 0, membershipSavings: 0, specialDiscount: 0 });

            return {
                total: total - specialDiscount,
                costs,
                savings: productSavings + membershipSavings,
                specialDiscount,
            };
        },
    }
}
</script>
<style lang="scss">
.summary-total {

    &__container {
        margin-top: 1rem;
        background-color: #fff;
        border-radius: 12px;
        -webkit-box-shadow: rgba(0,0,0,.08) 0 0.25rem 0.75rem;
        box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.08);
        padding: 2rem 1.5rem;
    }

    &__breakdown {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 1rem;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 500;
    }

    &__alert  {

        p {
            color: #757575;
            font-family: 'Sofia Regular', sans-serif;
            font-size: 14px;
            margin-bottom: 0.5rem;
            -webkit-font-smoothing: antialiased;
        }

    }



    &__savings {
        color: $nature500;
        font-weight: 500;
    }

    &__strikethrough {
        text-decoration: line-through;
        color: #a8a8a8;
    }
}
</style>
