<template>
    <div class="shop-hero">
        <h-carousel v-if="banners && banners.length > 0" v-slot="{ currentSlide }" :length="banners.length" :indicators="banners.map(slide => slide.carousel_indicators.mode)">
            <div
                v-for="(slide, index) in banners"
                :key="slide.title.value"
                :style="{ transform: `translateX(${getSlidePosition(currentSlide)})`, backgroundColor : slide.background_color}"
                class="shop-hero__slide"
                :class="{'shop-hero__inactive-slide': currentSlide !== index}"
            >
                <div class="shop-hero__slide-container">
                    <div class="shop-hero__slide-content">
                        <div class="shop-hero__slide-top">
                            <h-badge :label="slide.label_name.value" :dark="slide.label_name.mode === 'dark'" small no_border />
                            <h2 :class="{'shop-hero__slide-top--light': slide.title.mode === 'light'}">
                                {{ slide.title.value }}
                            </h2>
                        </div>
                        <div v-if="slide.action.value" class="shop-hero__slide-btn">
                            <h-button
                                :variant="slide.action.mode === 'light' ? 'secondary' : 'primary'"
                                :label="slide.action.value"
                                :handle-click="() => handleSlideAction(slide.action)"
                                :loading="visitLoading"
                                cta
                            />
                        </div>
                    </div>
                </div>
                <div class="shop-hero__slide-image" :style="{ backgroundColor: slide.background_color }">
                    <img :src="slide.image" alt="shop hero image">
                    <div class="gradient-overlay" :style="{ background: `linear-gradient(0deg, ${slide.background_color} 0%, ${slide.background_color}00 100%)` }" />
                </div>
            </div>
        </h-carousel>
        <div v-else class="shop-hero__noshop">
            <div class="shop-hero__slide">
                <div class="shop-hero__slide-container">
                    <div class="shop-hero__slide-top">
                        <h-badge label="shop" dark small no_border />
                        <h2>Our Products</h2>
                    </div>
                    <div class="shop-hero__noshop-sub">
                        Get started on a visit below
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';
import HCarousel from '@/components/Shared/HCarousel.vue';
import { handleScreenSize } from '@/helpers/responsive.js';
import { startVisitMethod } from '@/helpers/visit.js';
import IntakeService from '@/services/IntakeService';

export default {
    name: 'ShopHero',
    components: {
        HCarousel,
        HBadge,
        HButton,
    },
    props: {
        background: {
            type: String,
            default: '#F9F9F9',
        },
        banners: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isMobile: handleScreenSize(),
            visitLoading: false,
        };
    },
    computed: {
        ...mapState({
            organization: state => state.organization.data,
            user: (state) => state.user.user,
            incompleteIntakes: state => state.intake.incomplete_intakes,
        }),
    },
    created() {
        this.handleResize = () => {
            const screenSize = handleScreenSize();

            this.isMobile = screenSize.isMobile;
        };

        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        ...mapActions({
            getIntakeTemplate: 'intake/getIntakeTemplate',
            createIntakeInstance: 'intake/createIntakeInstance',
            saveProductTemplateId: 'productTemplates/saveSignupProductTemplateId',
        }),
        getSlidePosition(currentSlide) {
            const slideWidth = `${currentSlide * 100}%`;
            const padding = this.isMobile.isMobile ? '0px' : `${currentSlide * 32}px`;

            return `calc(-${slideWidth} - ${padding})`;

        },
        existingIntakeVisit(user, intakeId) {
            this.$router.push({
                path: `/consultation/hiw/${user}/${intakeId}/question/none`,
                query: { referrer: 'shop' },
            });
        },
        async newIntakeInstance(intakeTemplate) {
            window.localStorage.removeItem('intake_template');

            try {
                let intake = await this.createIntakeInstance({
                    templateId: intakeTemplate._id,
                    userId: this.user._id,
                    pharmacy_verification: true,
                });

                this.saveProductTemplateId(intakeTemplate.product_defaults[0]);
                window.localStorage.setItem('intake_template', JSON.stringify(intakeTemplate));
                this.visitLoading = false;
                this.$router.push({
                    path: '/consultation/hiw/' + intake.user + '/' + intake._id + '/question/none',
                    query: { referrer: 'shop' },
                });

            } catch (error) {
                this.visitLoading = false;
                throw error;
            }
        },
        async startVisit(intakeTemplateId) {
            this.visitLoading = true;

            await startVisitMethod.call(this, intakeTemplateId);
        },
        handleLabCheckout(productTemplateId) {
            localStorage.setItem('zp_product_template', JSON.stringify({
                id: productTemplateId,
                type: 'lab',
                referrer: 'shop',
            }));
            this.$router.push({
                path: '/signup/lab-visit',
            });
        },
        handleSlideAction(action) {
            switch (action.type) {
            case 'external-link':
                window.open(action.url, '_blank');
                break;
            case 'medication-visit':
                this.startVisit(action.url);
                break;
            case 'lab-visit':
                this.handleLabCheckout(action.url);
                break;
            case 'in-app-path':
                this.$router.push({ path: `/dashboard/${this.user._id}/${action.url}` });
                break;
            default:
                console.error('Unknown action type:', action.type);
            }
        },
    },
};
</script>
