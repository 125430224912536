<template>
    <div class="ppe-action-card__container" @click="on_action">
        <p class="ppe-small-title">{{title}}</p>
        <p class="ppe-description">{{description}}</p>
        <span class="ppe-action-card__icon" v-if="has_icon">
            <ion-icon :name="icon"></ion-icon>
        </span>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
    name: 'ActionCard',
    components: {},
    props: ['title', 'description', 'icon', 'has_icon', 'id'],
    data() {
        return {}
    },
    created() {
    },
    computed: {
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        on_action() {
            this.$emit('onAction', true);
        }
    }
}
</script>
<style lang="scss">

@import "@/assets/scss/patient-portal/shared.scss";
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.ppe-action-card {
    &__container {
        cursor: pointer;
        padding: 2rem;
        border: 1px solid $border-light;
        border-radius: $borderRadius;
        transition: all 0.3s ease;
        position: relative;

        &:hover {
            border: 1px solid $stone500;
        }

        .ppe-small-title {
            @include body;
            margin-bottom: 0.5rem;
            font-weight: 600;
        }

        .ppe-description {
            @include caption;
            margin-bottom: 0;
            max-width: 80%;
        }
    }

    &__icon {
        position: absolute;
        top: 42%;
        right: 1.5rem;
        font-size: 1.5rem;
    }


}
</style>
