<template>
	<div class="h-select__container">
		<select @input="$emit('input', $event.target.value) && vuelidate.$touch()" class="h-select__select">
			<option disabled value="" :selected="selected === '' || selected === null"></option>
			<option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.value === selected">{{ option.name }} </option>
		</select>
		<label class="h-select__label" :class="{ 'h-select__has-value': selected }">{{ label }}</label>
		<span class="error" v-if="error">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: 'HDropDown',
	props: {
		options: {
			type: Array,
			default: () => [],
		},
		selected: {
			type: String,
		},
		label: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
		},
		vuelidate: {
			type: Object,
			required: true,
		},
		error: {
			type: String,
		},
		add_shadow: {
			type: Boolean,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.h-select {
	&__container {
		position: relative;
		width: 100%;
		font-family: 'ZP-UI', sans-serif;
	}

	&__select {
		width: 100%;
		height: 62px;
		border: 0.0625rem solid $border-default;
		border-image: initial;
        border-radius: 0.75rem;
		background-color: $white-100;
		background: url("data:image/svg+xml;utf8,<svg class='icon-styleable-color' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' transform='matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,0,0)'><path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path><path d='M0 0h24v24H0V0z' fill='none'></path></svg>")
			#ffffff no-repeat right 12px bottom 18px;
		-webkit-font-smoothing: antialiased;
		-webkit-appearance: none;
		transition: all 200ms cubic-bezier(0.33, 0, 0, 1) 0s;
		padding: 1.5rem 1rem 0.5rem;
	}

	&__label {
		pointer-events: none;
		position: absolute;
		top: 50%;
		left: 16px;
		transform: translateY(-50%);
		color: $gray-700;
		font-size: 0.875rem;
		cursor: text;
		transition: all 200ms cubic-bezier(0.33, 0, 0, 1) 0s;
	}

	&__has-value {
		top: 8px !important;
		left: 14px !important;
		transform: scale(0.75) !important;
	}
}
</style>
