<template>
    <div class="messages-content">
        <div ref="messagesHistoryInner" class="messages-history">
            <EmptyMessage
                v-if="no_conversations"
                @start-conversation="onStartConversation"
            />
            <div
                v-else
                v-chat-scroll="{always: false, smooth: true, scrollonremoved:true}"
                class="messages-history-inner"
                @[read_condition]="updateReadStatus()"
                @v-chat-scroll-top-reached="retrieveMoreMessages()"
            >
                <div
                    v-for="(msg, index) in conversation_removed_duplicates(messages)"
                    :key="index"
                    class="cbmg-iteration-container"
                >
                    <Announcement v-if="msg.message_style === 2" :message="msg" />
                    <Default
                        v-if="msg.message_style === 1"
                        :conversation_details="conversation_details"
                        :last_patient_sent="last_patient_sent"
                        :message="msg"
                    />
                </div>
                <AdminTyping
                    v-if="admin_is_typing"
                    :first-name="conversation_details.patient_sender.first_name"
                    :last-name="conversation_details.patient_sender.last_name"
                />
            </div>
        </div>
        <BannerMessage />
        <MessageInput
            v-if="!no_conversations"
            v-model="message"
            :is-submitting="is_submitting"
            @submit-message="submitMessage"
            @typing-throttle="typingThrottle"
        />
    </div>
</template>

<script>
// TODO: import only the required lodash function
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import Pusher from 'pusher-js/with-encryption';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Announcement from '@/components/ConversationComponents/Announcement.vue';
import Default from '@/components/ConversationComponents/Default.vue';
import AdminTyping from '@/components/Dashboard/Messages/SubComponents/AdminTyping.vue';
import EmptyMessage from '@/components/Dashboard/Messages/SubComponents/EmptyMessage.vue';
import BannerMessage from '@/components/Dashboard/Messages/SubComponents/BannerMessage.vue';
import MessageInput from '@/components/Dashboard/Messages/SubComponents/MessageInput.vue';

//pusher instance
new Pusher(process.env.VUE_APP_PUSHER_KEY,
    {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        authEndpoint: process.env.VUE_APP_BASE_END + '/pusher/auth',
    },
);

export default {
    name: 'ConversationView',
    components: {
        BannerMessage,
        EmptyMessage,
        AdminTyping,
        MessageInput,
        Announcement,
        Default,
    },
    data() {
        return {
            message: '',
            dashboardUrl: null,
            is_submitting: false,
        };
    },
    async mounted() {
        await this.getConversations({ user: this.user });

        this.$nextTick(() => {
            this.$refs.messagesHistoryInner.addEventListener('scroll', this.handleScroll);

            this.scrollToEnd();
        });
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapMutations({
            startConversation: 'conversations/newConversation',
            updateConversationId: 'conversations/updateConversationId',
            resetConversation: 'conversations/resetConversation',
            appendMessage: 'conversations/appendMessage',
            resetPagination: 'conversations/resetPagination',
            incrementPagination: 'conversations/incrementPagination',
            resetPatientUnread: 'conversations/resetPatientUnread',
            resetAdminRead: 'conversations/resetAdminRead',
            showTyping: 'conversations/showTyping',
            closeTyping: 'conversations/closeTyping',
        }),
        ...mapActions({
            createConversation: 'conversations/createConversation',
            updatePatientReadStatus: 'conversations/updatePatientReadStatus',
            isTypingPatient: 'conversations/isTypingPatient',
            gatherMessages: 'conversations/gatherMessages',
            getConversations: 'conversations/getConversations',
            startNewConversation: 'conversations/startNewConversation',
            postOrCreateMessage: 'conversations/submitMessage',
        }),
        updateReadStatus() {
            const payload = {};

            payload['patient_id'] = this.conversation_details.patient;
            payload['conversation_id'] = this.conversation_id;
            payload['socket_id'] = this.socket_id;
            payload['sender_id'] = this.user._id;
            this.updatePatientReadStatus(payload);
        },
        async retrieveMoreMessages() {
            //create a payload object with a conversation_id
            const payload = {
                conversation_id: this.conversation_id,
                lastId: this.messages[0].created_at,
            };

            try {
                await this.gatherMessages(payload);
            } catch (e) {
                console.error(e);
            }
        },
        onStartConversation() {
            this.startConversation();
            this.$forceUpdate();
        },
        handleScroll() {
            const currentScrollTop = this.$refs.messagesHistoryInner.scrollY || this.$refs.messagesHistoryInner.scrollTop;

            if (currentScrollTop === 0) this.retrieveMoreMessages();
        },
        isTyping() {

            //create a payload object
            const payload = {};

            //assign a patient_id to the payload
            if (!this.conversation_details) {
                return;
            }
            payload.patient_id = this.conversation_details.patient;

            //assign a conversation id to the payload
            payload.conversation_id = this.conversation_id;

            //assign a socketId
            payload.socketId = this.socket_id;

            //assign a sender_id to the payload
            payload['sender_id'] = this.user._id;

            this.isTypingPatient(payload);

        },
        //only allow a call once every 3 seconds
        typingThrottle: _.throttle(function () {
            this.isTyping();
        }, 2000),
        conversation_removed_duplicates(conversation) {
            //remove all duplicae objects from the array, filter by message._id
            const uniqueArray = _.uniqBy(conversation, function (e) {
                return e._id;
            });

            //retur the new arraw without duplicates
            return uniqueArray;
        },
        async submitMessage() {
            //break out if there is no message
            if (!this.has_message || this.is_submitting) {
                return;
            }
            this.is_submitting = true;
            await this.postOrCreateMessage({
                user: this.user,
                message: this.message,
            });
            this.message = '';
            this.is_submitting = false;
            this.scrollToEnd();
        },
        scrollToEnd(smooth = true) {
            this.$nextTick(() => {
                const container = this.$refs.messagesHistoryInner;

                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: smooth ? 'smooth' : 'auto',
                });
            });
        },
    },
    computed: {
        ...mapState({
            conversation: state => state.conversations.conversation,
            new_conversation: state => state.conversations.new_conversation,
            messages: state => state.conversations.messages,
            conversation_id: state => state.conversations.conversation_id,
            conversation_details: state => state.conversations.conversation_details,
            loading: state => state.conversations.loading,
            error_retrieving_conversations: state => state.conversations.error_retrieving_conversations,
            read_attempting: state => state.conversations.read_attempting,
            admin_is_typing: state => state.conversations.admin_is_typing,
            presence_channel: state => state.conversations.presence_channel,
            user: state => state.user.user,
            socket_id: state => state.conversations.socket_id,
        }),
        ...mapGetters({
            last_patient_sent: 'conversations/last_patient_sent',
        }),
        no_conversations() {
            return !this.new_conversation && (!this.conversation || !this.conversation_details);
        },
        has_message() {
            return (this.message.length > 0);
        },
        //a listener for campaign_details.patient_read
        read_condition() {
            //return null if there is not cconversation_details or we are making call to the server
            if (this.conversation_details === null || this.read_attempting === true) {
                return null;
            }

            //create a mouseover listener if patient_read is false, there is a conversation_detail chosen, and is not making a call to the server
            if (!this.conversation_details.patient_read && this.conversation_details !== null && !this.read_attempting) {
                return 'mousemove';
            } else {
                return null;
            }
        },
    },
};

</script>
