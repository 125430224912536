<template>
    <div>
        <Nav stage="3" />
        <transition name="sidebar" mode="out-in">
            <h-sidebar v-if="open_sidebar" :open="open_sidebar" badge_label="Why Is This Needed" @handleClose="handleSidebar">
                <WhyVerify :organization_name="organizationName" />
            </h-sidebar>
        </transition>
        <div class="identity">
            <div class="identity__container" :class="{'identity__schedule': stage === 'schedule'}">
                <VerifyAccount v-if="stage === 'verify_account'" :handle-next="(step) => handleNext(step)">
                    <template #why>
                        <div class="identity__why" @click="handleSidebar">
                            Why Is This Needed?
                        </div>
                    </template>
                </VerifyAccount>
                <UploadId v-if="stage === 'id_image'" :handle-next="() => handleNext('existing_identity')" />
                <UploadProfile v-if="stage === 'profile_image'" :handle-next="() => handleNext('existing_identity')" />
                <ExistingIdentity v-if="stage === 'existing_identity'" :handle-next="(step) => handleNext(step)" @sectionChange="retakeImage">
                    <template #why>
                        <div class="identity__why" @click="handleSidebar">
                            Why Is This Needed?
                        </div>
                    </template>
                </ExistingIdentity>
                <Schedule
                    v-if="stage === 'schedule'"
                    :calcom-config="{ 'zp-type': 'user-verification', 'zp-intake-id': $route.params.intakeId }"
                    :calcom-link="link"
                    :handle-return="() => handleNext('verify_account')"
                    :handle-continue="handleContinue"
                />
                <div v-if="stage === 'verify_account' || stage === 'existing_identity'" class="identity__extra">
                    <div v-if="!hasImage()" class="identity__link" data-cypress="verify_later" @click="handleContinue">
                        I'll do this later
                    </div>
                </div>
                <h-certification />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Nav from '@/components/Consultation/Nav.vue';
import ExistingIdentity from '@/components/Identification/ExistingIdentity.vue';
import Schedule from '@/components/Identification/Schedule.vue';
import UploadId from '@/components/Identification/UploadId.vue';
import UploadProfile from '@/components/Identification/UploadProfile.vue';
import VerifyAccount from '@/components/Identification/VerifyAccount.vue';
import HSidebar from '@/components/Shared/HSidebar.vue';

import WhyVerify from '../components/Identification/SubComponents/WhyVerify.vue';
import HCertification from '../components/Shared/HCertification.vue';

const consultationCalcomLinks = {
    development: 'team/healthspan/dev01-identity-verification',
    dev01: 'team/healthspan/dev01-identity-verification',
    dev02: 'team/healthspan/dev02-identity-verification',
    staging: 'team/healthspan/staging-identity-verification',
    production: 'team/healthspan/user-verification',
};

export default {
    components: {
        Nav,
        UploadId,
        UploadProfile,
        ExistingIdentity,
        VerifyAccount,
        HSidebar,
        WhyVerify,
        Schedule,
        HCertification,
    },
    data() {
        return {
            identity_section: null,
            footerButton: null,
            open_sidebar: false,
            link: consultationCalcomLinks[process.env.NODE_ENV] || consultationCalcomLinks.production,
        };
    },
    computed: {
        ...mapState({
            questionnaireData: state => state.questionnaire.questionnaireData,
            organizationSettings: state => state.organizationSettings,
            userData: state => state.authenticate.user,
        }),
        organizationName() {
            return this.organizationSettings.data.name;
        },
        //determine which component (id upload or profile upload) based on the route path
        stage() {
            return this.verification ? this.$route.params.steps : this.$route.params.photoType;
        },
        verification() {
            return this.$route.name === 'verification';
        },
    },
    methods: {
        ...mapActions({
            getUser: 'user/retrieveUser',
        }),
        hasImage() {
            return this.userData && !!this.userData && !!this.userData.image && !!this.userData.image.id.id_url && !!this.userData.image.profile.profile_url;
        },
        retakeImage() {
            this.identity_section = 'id_image';
        },
        handleSidebar() {
            this.open_sidebar = !this.open_sidebar;
            // eslint-disable-next-line no-console
            console.log('sidebar', this.open_sidebar);
        },
        handleNext(step) {
            const { intakeId } = this.$route.params;

            if (this.verification) {
                return this.$router.push({ name: 'verification', query: this.$route.query, params: { steps: step } });
            }

            if (!intakeId) {
                return this.$router.push({ name: 'dashboard', params: { userId: this.userData._id, page: 'profile' } });
            }

            const identityParams = {
                section: 'identification-step',
                intakeId,
                photoType: step,
            };

            this.$router.push({ name: 'identification-step-alt', query: this.$route.query, params: identityParams });
        },
        handleContinue() {
            const { intakeId } = this.$route.params;

            this.verification || !intakeId
                ? this.$router.push({ name: 'dashboard', params: { userId: this.userData._id, page: 'profile' } })
                : this.$router.push({ name: 'intake-default-alt', query: this.$route.query, params: { section: 'checkout', intakeId } });
        },
    },
};
</script>

<style lang="scss">

.video-id-wrapper {
    width: 100%;
    background: #fff;
    position: relative;
}

.video-id-wrapper video {
    width: 100%;
}

.image-example-section {
    width: 100%;
    padding: .5rem 0;
    background: #fff;
    margin-bottom: 2rem;
}

.image-example-section-inner-wrapper {
    width: 100%;
    max-width: 220px;
    margin: .5rem auto;
    position: relative;
    padding: 0 1rem;
    height: 220px;
}

.image-example-section-inner-wrapper img {
    width: 100%;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    padding: 0 .5rem;
    transform: translate(-50%, -50%);
}

.centered-label-text {
    line-height: 56px;
    text-align: center;
}

.id-canvas {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

#canvas {
    display: none;
}

.exit-photo-booth {
    position: absolute;
    padding: calc(0.5rem + 2px) 1rem calc(0.5rem - 2px);
    background: rgba(0, 0, 0, 0.44);
    color: #fff;
    bottom: 90px;
    right: 30px;
    font-family: 'Sofia Regular', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.id-canvas .retake-button {
    position: absolute;
    padding: calc(0.5rem + 2px) 1rem calc(0.5rem - 2px);
    background: rgba(0, 0, 0, 0.44);
    color: #fff;
    bottom: 20px;
    right: 20px;
    font-family: 'Sofia Regular', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;

}

.id-canvas .retake-button:hover {
    background: #000;
}

.id-canvas img {
    width: 100%;
    border-image: initial;
}

.checklist-header {
    font-size: 1.125rem;
    margin: 30px 0 0.5rem;
    font-family: 'Sofia Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.checlist-item-photo {
    padding-left: 25px;
    position: relative;
    margin-bottom: 0.25rem;
    list-style: none;
    font-family: 'Sofia Regular', sans-serif;
}

.checlist-item-photo svg {
    position: absolute;
    left: 0;
    top: 0;
}

.consultation-identity-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

</style>
