<template>
<div>
        <form id="upload-id" class="verification" @submit.prevent="">
            <div class="identity__header">
            <h1>Provide a Selfie</h1>
        </div>

        <div v-if="!existingImage" class="upload__container">

            <div class="upload__wrapper">
                <div class="identity__header--small">
                    <h2>Take or upload a selfie</h2>
                    <p class="text-dim-80">To match your face to your photo</p>
                </div>

                <!--image has not been uploaded-->
                <div v-if="!image" class="upload__container">
                    <div v-if="!camera_photo" class="upload__image">
                        <div class="upload__img-container">
                            <img src="@/assets/images/verification/selfie_icon.svg"/>
                        </div>
                        <div class="upload__requirements">
                            <ul>
                                <li>Max file size 10mb</li>
                                <li>PNG, JPG, TIFF, HEIC</li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="camera_photo" class="upload__container">
                        <video id="video" ref="video" autoplay></video>
                        <div class="upload__buttons">
                            <h-button
                                    id="snap"
                                    type="button"
                                    :label="snap_button_text"
                                    :handleClick="capture"
                                    variant="primary"
                                    wide
                                    fulll_width
                                    large
                                >
                            </h-button>
                        </div>
                        <canvas id="canvas" ref="canvas"></canvas>
                    </div>

                    <div v-if="!camera_photo" class="upload__buttons">
                        <div class="upload__buttons--take">
                            <h-button label="Take Photo" variant="primary" full_width large :handleClick="() => openCanvas()"></h-button>
                        </div>
                        <div class="upload__buttons--upload">
                            <label class="upload__file-button" for="file">
                                <span class="upload__file-button--mobile">Take Photo</span><span class="upload__file-button--desktop">Upload Photo</span>
                            </label>
                            <input id="file"
                                ref="file"
                                class="hidden-radio"
                                type="file"
                                accept="image/*"
                                v-on:click="handleSelectPhotoClick()"
                                v-on:change="handleFileUpload()"/>

                        </div>
                    </div>
                </div>

                <!-- if image is uploaded-->
                <div v-if="image">
                    <!--image canvas-->
                    <div class="upload__container">
                        <div class="id-canvas">
                            <img :src="image"/>
                        </div>
                        <div class="upload__buttons">
                            <div v-if="!general_error && !file_size_error" class="identity__link" @click="resetImage(upload_type)">Retake</div>
                        </div>
                    </div>

                    <div v-if="file_size_error || general_error" class="upload__error">
                        <img src="@/assets/images/verification/warning.svg" alt="" />
                        <p v-if="file_size_error" class="identity__body">{{ file_size_error || 'File size too big. Please make sure image is under 10mb.'}}</p>
                        <p v-if="general_error" class="identity__body">{{ general_error || 'There was an error. Please try again.' }}</p>
                    </div>

                    <h-button v-if="file_size_error || general_error" label="Retry" variant="secondary" full_width large :handleClick="resetImage"></h-button>
                </div>
            </div>
            <div v-if="image && !file_size_error && !general_error" class="upload__buttons">
                    <h-button
                        label="Continue"
                        :loading="is_submitting"
                        variant="primary"
                        full_width
                        large
                        :handle-click="submitFile"
                    />
            </div>
        </div>

        <div v-if="existingImage" class="upload__container">
            <div class="upload__wrapper">
                <div class="identity__header--small">
                    <h2>Take or upload a valid ID</h2>
                    <p class="text-dim-80">Used to verify your personal information is correct.</p>
                </div>

                <!--image canvas-->
                <div class="id-canvas">
                    <img :src="existingImage"/>
                </div>
                <div class="identity__link" @click="resetImage()" data-cypress="retake">Retake</div>
            </div>
        </div>
    </form>

    <div v-if="existingImage" class="verification upload__buttons-container">
        <h-button label="Continue" :loading="is_submitting" :handleClick="continueExistingImage" variant="primary" full_width large/>
    </div>
    <div v-if="!image && !existingImage" class="identity__link-container">
        <div class="identity__link" @click="handleNext">Cancel</div>
    </div>
        <CameraPermissions
            ref="cameraHandler"
            @trigger-file-input="triggerFileInput"
            @start-camera="handleStartCamera"
        />
</div>
</template>


<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { tags } from '@/constants/mailchimpTags';
import { analytics, analyticsActions } from '@/helpers/analytics'

import HButton from '../Shared/HButton.vue';
import CameraPermissions from './SubComponents/CameraPermissions.vue';

export default {
    name: 'UploadProfile',
    props: {
        handleNext: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            camera_photo: false,
            file: '',
            image: '',
            //section_type can be id_images or profile_images
            section_type: 'profile_images',
            mediaStream: null,
            video: {},
            canvas: {},
            captures: [],
            base64data: null,
            is_submitting: false,
            snap_button_text: 'Snap Photo',
            existingImage: false,
            file_size_error: null,
            general_error: null,
            upload_type: null,
        }
    },
    components: {
        HButton,
        CameraPermissions,
    },
    async created() {
        await this.getUser({
            user: this.userData._id
        })

        if (this.userData.image.profile.profile_url) {
            this.existingImage = this.userData.image.profile.profile_url + `?${Date.now()}`;
        }
    },
    mounted() {
        if (!this.existingImage && !this.image && !this.camera_photo) {
            this.$refs.file.addEventListener('change', this.handleFileChange);
        }
    },
    beforeDestroy() {
        if (!this.existingImage && !this.image && !this.camera_photo) {
            this.$refs.file.removeEventListener('change', this.handleFileChange);
        }
    },
    methods: {
        triggerFileInput() {
            this.$refs.file.click();
        },
        handleFileChange(event) {
            if (event.target.files.length > 0) {
                this.$refs.cameraHandler.showWebcamInstructions = false;
            }

            if (!this.existingImage && !this.image && !this.camera_photo) {
                this.$refs.file.removeEventListener('change', this.handleFileChange);
            }
        },
        async resetReuploadStatuses(){
            //update intake
            let vm = this;

            const { intakeId } = vm.$route.params;

            try {
                if (intakeId) {
                    const intakePayload = {
                        organization: vm.orgId,
                        user_id: vm.$route.params.userId,
                        intake_id: intakeId,
                        update_fields: {
                            awaiting_patient_update: {
                                is_awaiting: false,
                                reason: null,
                                hold_date: null,
                            },
                        },
                    };

                    await vm.updateIntake(intakePayload);
                }
            } catch (err) {
                vm.is_submitting = false;
                return;
            }
            vm.$router.push({path: '/dashboard/' + vm.$route.params.userId + '/profile'})
        },
        async continueExistingImage() {
            this.is_submitting = true;

            if (this.$router.currentRoute.name === "photoreupload") {
                return this.resetReuploadStatuses();
            }

            try {

                if (this.mailchimp_integration && this.userData.mailchimp_id) {
                    await this.add_subscriber_tag({
                        tag: {
                            name: tags.status.verification.complete,
                            status: 'active'
                        },
                        patient: this.userData._id
                    })
                    await this.add_subscriber_tag({
                        tag: {
                            name: tags.status.verification.incomplete,
                            status: 'inactive'
                        },
                        patient: this.userData._id
                    })
                }
                this.handleNext();
                this.is_submitting = false;
            } catch (e) {
                console.log('continueExistingImage error:', e);
                this.is_submitting = false;
            }

        },
        openCanvas() {
            analytics.track(analyticsActions.identification_profile_take_photo);

            this.upload_type = 'camera';
            this.$refs.cameraHandler.checkPermissions();
        },
        handleStartCamera(stream) {
            this.mediaStream = stream;
            this.startCamera();
        },
        startCamera() {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((mediaStream) => {
                        this.$refs.cameraHandler.showWebcamPrompt = false;
                        this.mediaStream = mediaStream;
                        this.camera_photo = true;

                        this.$nextTick(() => {
                            if (this.$refs.video) {
                                this.$refs.video.srcObject = mediaStream;

                                this.$refs.video.play();
                            }
                        });
                    });
            }
        },
        //user will snap a picture from the video
        capture() {
            analytics.track(analyticsActions.identification_profile_snap_photo);

            let vm = this;

            this.snap_button_text = 'Say Cheese! Wait While We Take Photo';

            if (!("ImageCapture" in window)) {
                this.safariImageCapture();
                return;
            }
            //take a track of video now i.e. {kind:video, label : "FaceTime HD Camera"}
            const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
            const imageCapture = new window.ImageCapture(mediaStreamTrack);
            //snap the image via takePhoto
            return imageCapture.takePhoto().then(image => {
                var reader = new FileReader();
                //set the read image to the img video
                reader.onload = (e) => {
                    vm.image = e.target.result;
                }
                //convert the image into base64
                reader.readAsDataURL(image);
                //set the base 64 data on completion
                reader.onloadend = function () {
                    var base64data = reader.result;
                    vm.base64data = base64data;
                    //create a new file from the base64 data
                    let new_file = vm.dataURLtoFile(vm.base64data, 'hello.png');
                    vm.file = new_file;

                    analytics.track(analyticsActions.identification_profile_photo_taken, {
                        browser: 'not_safari',
                    });
                }
                this.mediaStream.getVideoTracks().forEach(function (track) {
                })

                this.stopCamera();
                this.snap_button_text = 'Snap Photo';
            })

        },
        safariImageCapture() {
            let vm = this;
            const video = document.createElement("video");
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            video.srcObject = this.mediaStream;
            return new Promise((resolve, reject) => {
                video.addEventListener("loadeddata", async () => {
                    const {videoWidth, videoHeight} = video;
                    canvas.width = videoWidth;
                    canvas.height = videoHeight;
                    try {
                        await video.play();
                        context.drawImage(video, 0, 0, videoWidth, videoHeight);
                        vm.image = canvas.toDataURL(resolve, "image/png");
                        let new_file = vm.dataURLtoFile(vm.image, "hello.png")
                        vm.file = new_file;

                        analytics.track(analyticsActions.identification_profile_photo_taken, {
                            browser: 'safari',
                        });

                        this.stopCamera();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        },
        stopCamera() {
            if (this.mediaStream !== null) {
                this.mediaStream.getVideoTracks().forEach(function (track) {
                    track.stop();
                })
            }
        },

        //convert base64 data to file
        dataURLtoFile(dataurl, filename) {
            //divide the url
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                //decode the base64 string
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            //complete the creation of the file
            return new File([u8arr], filename, {type: mime});
        },
        handleSelectPhotoClick() {
            analytics.track(analyticsActions.identification_profile_select_photo)
            this.upload_type = 'upload';
        },
        handleFileUpload() {
            analytics.track(analyticsActions.identification_profile_photo_selected)

            //when file is changed we set the local file var to the first File
            //object in the FileList on the input[type="file"]
            this.file = this.$refs.file.files[0];

            //show uploaded image
            this.createImage(this.file);
        },

        createImage(file) {

            //Image constructor creates a new HTMLImageElement
            var image = new Image();
            //FileReader object lets the app asynchronously read content of file
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            }
            reader.readAsDataURL(file);

        },
        resetImage(path) {
            analytics.track(analyticsActions.identification_profile_retake_photo);

            this.file = "";
            this.image = "";
            this.existingImage = false;
            this.camera_photo = false;
            this.file_size_error = null,
            this.general_error = null,
            this.stopCamera();
            if (path === "camera") {
                this.openCanvas();
            }
        },

        //function to submit file if this is a reupload from a dashboard referral
        async reuploadFile() {
            let formData = new FormData();
            formData.append('file', this.file);
            const { intakeId } = this.$route.params;

            if (intakeId) {
                formData.append('intake', intakeId);
            }

            formData.append("client_id", this.$route.params.userId);
            formData.append('image_type', this.section_type);
            formData.append('reupload_image', true);
            let vm = this;
            vm.is_submitting = true;

            //upload image
            try {
                await vm.uploadProfileImage(formData);
            } catch (err) {
                vm.is_submitting = false;
                return;
            }

            return vm.resetReuploadStatuses();
        },
        returnVerificationIntakeId() {
            let intakeId = this.intake_id;
            if (this.$route.name === 'verification' && this.userData.intakes && this.userData.intakes.length > 0) {
                intakeId = this.userData.intakes[0];
            }
            return intakeId;
        },
        async submitFile() {
            analytics.track(analyticsActions.identification_profile_submitted);

            //change state
            this.file_size_error = false;
            this.general_error = false;
            this.is_submitting = true;

            //implement a new FormData object
            let formData = new FormData();

            //default is not reupload
            let is_reupload = false;

            //exit out of function if this is a reupload from the dashboard
            if (this.$router.currentRoute.name === 'photoreupload') {
                //set reupload image to true
                return this.reuploadFile();
            }

            //append the file to the formData
            formData.append('file', this.file);

            //append clientId to the file
            formData.append('client_id', this.userData._id);

            //choose section type
            formData.append('image_type', this.section_type);

            //this is not a reupload section
            formData.append('reupload_image', false);

            const intakeId = this.returnVerificationIntakeId();

            if (intakeId) {
                //add a questionnaire ID number
                formData.append('intake', intakeId);
            }

            let vm = this;

            //submit photo id

            try {
                await this.uploadProfileImage(formData);
                if (this.mailchimp_integration && this.userData.mailchimp_id) {
                    await this.add_subscriber_tag({
                        tag: {
                            name: tags.status.verification.complete,
                            status: 'active'
                        },
                        patient: this.userData._id
                    });
                    await this.add_subscriber_tag({
                        tag: {
                            name: tags.status.verification.incomplete,
                            status: 'inactive'
                        },
                        patient: this.userData._id
                    });
                }
                this.handleNext();
                await this.$nextTick();
                this.is_submitting = false;

            } catch (err) {
                if ('message' in err && err.text === 'FileSizeLimitError') {
                    this.file_size_error = err.message;
                } else {
                    this.general_error = err.message;
                }
                this.is_submitting = false;
            }
        },
        ...mapMutations({
            updateUser: 'authenticate/updateUser',
            initializeQuestionnaireStore: 'questionnaire/initializeQuestionnaireStore',
            setUser: 'user/setUser'
        }),
        ...mapActions({
            uploadProfileImage: "intake/uploadProfileImage",
            updateIntake: "intake/updateIntake",
            getUser: 'user/retrieveUser',
            add_subscriber_tag: 'user/addSubscriberTags'
        })
    },
    computed: {
        ...mapState({
            questionnaireData: state => state.questionnaire.questionnaireData,
            userData: state => state.user.user,
            intake_id: (state) => state.intake.intake_id,
            selected_plan: state => state.questionnaire.selected_plan,
            orgId: state => state.orgId.data,
            mailchimp_integration: state => state.integrations.mailchimp_exists.data
        }),
        ...mapGetters({
            photoMismatch: 'alerts/photoMismatch'
        })
    }
}
</script>
