import moment from 'moment-timezone';

import { getNthWeekdayOfMonth } from './getNthWeekdayOfMonth';

const HOLIDAYS = [
    '01-01', // New Year's Day
    '07-04', // Independence Day
    '11-11', // Veterans Day
    '12-25', // Christmas Day
];

function isDynamicHoliday(date) {
    const year = date.year();

    const martinLutherKingJrDay = getNthWeekdayOfMonth(year, 1, 1, 3); // 3rd Monday of January
    const presidentsDay = getNthWeekdayOfMonth(year, 2, 1, 3); // 3rd Monday of February
    const laborDay = getNthWeekdayOfMonth(year, 9, 1, 1); // 1st Monday of September
    const thanksgiving = getNthWeekdayOfMonth(year, 11, 4, 4); // 4th Thursday of November

    let memorialDay = getNthWeekdayOfMonth(year, 5, 1, 5);

    if (!memorialDay) {
        // If the 5th Monday doesn't exist, fall back to the 4th Monday
        memorialDay = getNthWeekdayOfMonth(year, 5, 1, 4);
    }

    return (
        date.isSame(martinLutherKingJrDay, 'day') ||
        date.isSame(presidentsDay, 'day') ||
        date.isSame(memorialDay, 'day') ||
        date.isSame(laborDay, 'day') ||
        date.isSame(thanksgiving, 'day')
    );

}

/*
    Timing: The bar should only appear during specific times when
    it's after hours (4PM-9AM PT on weekdays and all day on weekends)
    and on Holidays (
        New Year’s Day,
        Martin Luther King Jr Day,
        Presidents' Day,
        Memorial Day,
        Independence Day,
        Labor Day,
        Veterans Day,
        Thanksgiving Day,
        Christmas Day,
    ).
*/
export function getMessengerBannerType(timestamp) {
    const date = moment.tz(timestamp, 'America/Los_Angeles');

    const isStaticHoliday = HOLIDAYS.includes(date.format('MM-DD'));
    const isHoliday = isStaticHoliday || isDynamicHoliday(date);

    if (isHoliday) {
        return { type: 'holiday' };
    }

    const isAfterHours = date.hour() < 9 || date.hour() >= 16; // 9 AM–4 PM weekdays

    if (isAfterHours) {
        return { type: 'after-hours' };
    }

    return null;
}
