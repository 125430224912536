export const newSignupConfigTransorm = (intakeTemplate, params) => {
    if (!intakeTemplate || !Object.keys(intakeTemplate).length) return null;

    const config = {
        intake: {
            hasIntake: true,
            id: intakeTemplate._id,
        },
        membership: {
            hasMembership: intakeTemplate.plan_checkout_options.membership_choice_step,
        },
        product: {
            main_products: intakeTemplate.product_defaults,
            addon_products: intakeTemplate.product_addons,
            bundle_support: intakeTemplate.bundle_support,
        },
        lab: {
            product: params ? params.labProductId : null,
        },
    };

    if (params && 'productTemplateId' in params) {
        if (params.productTemplateId) {
            config.product['default'] = params.productTemplateId;
        }
    } else {
        config.product['default'] = intakeTemplate.product_templates[0];
    }

    return config;
};
