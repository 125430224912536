<template>
    <h-sidebar
        :open="active"
        badge_preset="green"
        badge_label="Manage"
        badge_size="small"
        @handleClose="on_close"
    >
        <div v-if="view === 'default'" class="subscription-modal__body">
            <div class="subscription-management-modal__header">
                <h2 class="subscription-management-modal__title">
                    Welcome back, {{ user.first_name }}!
                </h2>
                <p class="subscription-management-modal__subtitle">
                    What would you prefer to do?
                </p>
            </div>
            <div class="subscription-modal__form">
                <action-card
                    title="Resume and order now"
                    description="Resume your subscription and ship your medication"
                    :has_icon="true"
                    icon="arrow-forward-outline"
                    @onAction="on_resume_subscription_submit('resume_renewal')"
                />
                <action-card
                    :title="resume_renewal"
                    description="Resume your treatment and ship your medication on your next renewal"
                    :has_icon="true"
                    icon="arrow-forward-outline"
                    @onAction="on_resume_subscription_submit('resume_now')"
                />
            </div>
            <info-well
                title="We're here to help!"
                description="If you're having any trouble, please get in touch."
                action_title="Message Us"
                :has_action="true"
                @onAction="message"
            >
            </info-well>
        </div>
        <div v-if="view === 'success'" class="subscription-modal__body">
            <SubscriptionSuccessModalSlide />
            <HButtonV2
                size="large"
                fullWidth
                variant="secondary"
                @click="on_close"
            >
                Close
            </HButtonV2>
        </div>
    </h-sidebar>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ActionCard from "@/components/Shared/ActionCard.vue";
import HSidebar from '@/components/Shared/HSidebar.vue';
import InfoWell from '@/components/Shared/InfoWell';
import SubscriptionSuccessModalSlide from '@/components/Dashboard/Modals/SubscriptionSuccessModalSlide.vue';
import HButtonV2 from '@/components/Shared/HButtonV2';

export default {
    name: 'SubscriptionResumeModal',
    components: {
        ActionCard: ActionCard,
        HSidebar,
        InfoWell,
        SubscriptionSuccessModalSlide,
        HButtonV2,
    },
    props: {
        subscription: {
            type: Object,
            required: true,
        },
        vendor: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            resume_renewal: null,
            view: 'default',
        }
    },
    created() {
        this.resume_renewal = `Resume and ship on ${this.$options.filters.unix_date(this.vendor.current_period_end, 'MMM Do') }`
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            button_text: state => state.subscriptions.buttons,
            update_subscription: state => state.subscriptions.update_subscription
        })
    },
    methods: {
        ...mapActions({
            resumeSubscription: 'subscriptions/resumeSubscription',
        }),
        on_close() {
            this.$emit('onModalClose', false);
        },
        async on_resume_subscription_submit(variant) {
            const payload = {
                subscriptionId: this.subscription._id,
                data: {
                    shipNow: variant === 'resume_now',
                },
            };

            try {
                await this.resumeSubscription(payload);
                this.$emit('onSuccess');

                this.view = 'success';
            } catch (error) {
                console.error('on_resume_subscription_submit error', error);
            }
        },
        message() {
            this.$router.push({ path: `/messages/${this.user._id}/new` });
        },
    }
}
</script>
<style lang="scss">
.subscription-resume {
    &__container {

    }
}
</style>
