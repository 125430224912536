import axios from "axios";
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const productTemplates = {
    namespaced: true,
    state: {
        upsellProductTemplates: {
            loading: false,
            data: null,
        },
        signupProductTemplate: null,
        labProductId: null,
        selectedProduct: null,
        productTemplatesMap: new Map(),
        productTemplatesLoading: false,
    },
    mutations: {
        setUpsellProductTemplatesData(state, data) {
            state.upsellProductTemplates.data = data;
        },
        setUpsellProductTemplatesLoading(state, loading) {
            state.upsellProductTemplates.loading = loading;
        },
        setSignupProductTemplateId(state, data) {
            state.signupProductTemplate = data;
        },
        setSelectedProduct(state, { product, referrer = null }) {
            state.selectedProduct = {
                ...product,
                id: product._id,
                referrer,
            };
        },
        setProductTemplatesMap(state, productTemplates) {
            productTemplates.forEach((product) => {
                if (!state.productTemplatesMap.has(product._id)) {
                    state.productTemplatesMap.set(product._id, product);
                }
            });
        },
        setProductTemplatesLoading(state, loading) {
            state.productTemplatesLoading = loading;
        },
    },
    actions: {
        getManyProductTemplatesByIds({ commit, rootState }, payload) {
            let orgId = rootState.orgId.data;

            commit('setUpsellProductTemplatesLoading', true);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/product-templates/' + orgId + '/many',
                    method: 'GET',
                    params: { ids: payload.ids },
                })
                    .then((response) => {
                        commit('setUpsellProductTemplatesData', response.data);
                        resolve(response.data);
                        commit('setUpsellProductTemplatesLoading', false);
                    })
                    .catch((err) => {
                        reject(err);
                        commit('setUpsellProductTemplatesLoading', false);
                    });
            });
        },
        saveSignupProductTemplateId({ commit }, payload) {
            commit('setSignupProductTemplateId', payload);
        },

        async getProductTemplatesDataByIds({ commit, state, rootState }, payload) {
            try {
                commit('setProductTemplatesLoading', true);

                const response = await axios.get(`${baseUrl}/product-templates/${rootState.orgId.data}/many`, {
                    params: {
                        ids: payload.ids.filter(id => !state.productTemplatesMap.has(id)),
                    },
                });

                commit('setProductTemplatesMap', response.data);
                commit('setProductTemplatesLoading', false);
            } catch (error) {
                commit('setProductTemplatesLoading', false);
                throw error;
            }
        },
    },
};
