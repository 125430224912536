import moment from 'moment-timezone';

/**
 * Get the nth occurrence of a specific weekday in a month.
 *
 * @param {number} year - The year (e.g., 2024)
 * @param {number} month - The month (1 = January, 2 = February, etc.)
 * @param {number} weekday - The ISO weekday number (1 = Monday, ..., 7 = Sunday)
 * @param {number} nth - The nth occurrence of the weekday
 * @param {string} timezone - The timezone (default: 'America/Los_Angeles')
 * @returns {moment.Moment} The date of the nth occurrence of the weekday
 */
export function getNthWeekdayOfMonth(year, month, weekday, nth, timezone = 'America/Los_Angeles') {
    const date = moment.tz(`${year}-${String(month).padStart(2, '0')}-01`, timezone); // Start at the 1st of the month

    let count = 0;

    while (date.month() + 1 === month) { // Stay within the month
        if (date.isoWeekday() === weekday) { // Match the specified weekday
            count++;

            if (count === nth) {
                return date.clone(); // Return the nth occurrence
            }
        }
        date.add(1, 'day'); // Move to the next day
    }

    return null;
}
