<template>
    <div>
        <transition name="sidebar" mode="out-in">
            <h-sidebar v-if="open_sidebar" :open="open_sidebar" badge_label="Policy" @handleClose="handleSidebar">
                <div v-html="consentPolicyHtml || ''" />
            </h-sidebar>
        </transition>
        <form class="signup-form" @submit.prevent="onSignupSubmit()">
            <div class="signup-form__login h-caption" @click="redirectToLogin">
                Already a member? <a href="/#/login" class="auth__link" data-test="login-link">Log in and continue</a>
            </div>
            <div class="signup-form__input">
                <div class="signup-form__input__row">
                    <Input
                        id="firstName"
                        v-model="userObject.first_name"
                        name="firstName"
                        label="First Name"
                        :vuelidate="$v.userObject.first_name"
                        :errors="[$v.userObject.first_name.$invalid && $v.userObject.first_name.$dirty && 'Enter your first name.']"
                        class="flex-1"
                    />
                    <Input
                        id="lastName"
                        v-model="userObject.last_name"
                        name="lastName"
                        label="Last Name"
                        :vuelidate="$v.userObject.last_name"
                        :errors="[$v.userObject.last_name.$invalid && $v.userObject.last_name.$dirty && 'Enter your last name.']"
                        class="flex-1"
                    />
                </div>
                <div :class="{'signup-form__input__error': submitted && duplicate.duplicate_value}">
                    <Input
                        id="email"
                        v-model="userObject.email"
                        name="email"
                        label="Email"
                        :vuelidate="$v.userObject.email"
                        :errors="[
                            !$v.userObject.email.email_validator && $v.userObject.email.$dirty && 'Please enter a valid email address',
                        ]"
                    />
                    <div v-if="submitted && duplicate.duplicate_value" class="signup-form__input__error" @click="redirectToLogin">
                        An account with this email already exists. If you are the owner of this account please <a href="/#/login">Login</a>
                    </div>
                </div>
                <Input
                    id="password"
                    v-model="userObject.password"
                    name="password"
                    type="password"
                    label="Password"
                    :vuelidate="$v.userObject.password"
                    :errors="[
                        !$v.userObject.password.required && $v.userObject.password.$dirty && 'Please enter your password.',
                        !$v.userObject.password.minlength && $v.userObject.password.$dirty && 'Password must be at least 6 characters long.',
                    ]"
                />
            </div>
            <div class="signup-form__checkbox">
                <h-checkbox :checked="tos" @input="handleCheckboxChange">
                    I agree to the <a class="auth__link" @click="openTosPolicy()">Terms and Conditions</a>,
                    <a class="auth__link" @click="openPrivacyPolicy()">Privacy Policy</a> and
                    <a class="auth__link" @click="handleSidebar">Telehealth Consent</a>
                </h-checkbox>
            </div>
            <h-button
                label="Start My Visit"
                variant="primary"
                full_width
                large
                :handle-click="onSignupSubmit"
                :loading="submitted && !duplicate.duplicate_value"
                :disabled="$v.$invalid"
            />
        </form>
    </div>
</template>
<script>

import LogRocket from 'logrocket';
import { helpers, maxLength, minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';
import { mapState } from 'vuex';

import Input from '@/components/Auth/Input.vue';
import HButton from '@/components/Shared/HButton.vue';
import HCheckbox from '@/components/Shared/HCheckbox.vue';
import HSidebar from '@/components/Shared/HSidebar.vue';
import { analytics, analyticsActions } from '@/helpers/analytics';
import { formatName } from '@/helpers/strings';
import IntakeService from '@/services/IntakeService';
import { PRODUCT_TYPES } from '@/constants/product';
import { getSignupGclid } from '@/domains/user/lib/getSignupClid';
import { newSignupConfigTransorm } from '@/domains/intake/makeIntakeTemplateConfig';

const emailValidator = helpers.regex('isEmailAddress', /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/);

export default {
    components: {
        HSidebar,
        Input,
        HCheckbox,
        HButton,
    },
    props: {
        product: {
            type: Object,
        },
        product_template_id: {
            type: String,
            required: false,
            default: null,
        },
    },
    name: 'SignupForm',
    data() {
        return {
            userObject: {
                email: '',
                first_name: '',
                last_name: '',
                password: '',
            },
            tos: false,
            open_sidebar: false,
            submitted: false,
            consentPolicyHtml: null,
            product_type: null,
            signupConfig: null,
        };
    },
    validations: {
        userObject: {
            email: { required, minlength: minLength(4), email_validator: emailValidator },
            first_name: { required, minlength: minLength(2), maxLength: maxLength(35) },
            last_name: { required, minlength: minLength(2), maxLength: maxLength(35) },
            password: { required, minlength: minLength(6) },
        },
        tos: {
            checked: (val) => {
                return val;
            },
        },
    },
    watch: {
        public_product: {
            async handler(product) {
                if (product.data && product.data.product_template) {
                    if (this.public_product.data.product_template.type) {
                        this.product_type = this.public_product.data.product_template.type;
                        await this.initIntakeTemplate();
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
    async created() {

    },
    methods: {
        ...mapMutations({
            initializeUserStore: 'user/initializeUserStore',
            initializeAuthUserStore: 'authenticate/initializeUserStore',
        }),
        ...mapActions({
            createUser: 'authenticate/createUser',
            createIntakeInstance: 'intake/createIntakeInstance',
            getOrganizationSettings: 'getOrganizationSettings',
            getGlobalIntake: 'intake/getGlobalIntake',
            retrieveOrganizationConsentPolicy: 'retrieveOrganizationConsentPolicy',
            getProductIntake: 'intake/retrieveProductIntake',
        }),
        redirectToLogin() {
            this.$emit('changeType', 'login');
        },
        handleCheckboxChange(isChecked) {
            this.tos = isChecked;
        },
        async handleSidebar() {
            try {
                const payload = { organization: this.orgId.data, section: 'consent' };
                const consentPolicy = await this.retrieveOrganizationConsentPolicy(payload);

                if (consentPolicy.use_external_link) {
                    this.openExternalLink(this.consent_policy.external_link_url);

                    return;
                }

                this.open_sidebar = !this.open_sidebar;
                this.consentPolicyHtml = this.consent_policy.policy_html;
            } catch (error) {
                console.error(error);
            }

        },
        openExternalLink(externalLink) {
            window.open(externalLink);
        },
        async openTosPolicy() {
            if (this.tos_policy) {
                this.openExternalLink(this.tos.external_link_url);

                return;
            } else {
                try {
                    const payload = { organization: this.orgId.data, section: 'tos' };
                    const tosPolicy = await this.retrieveOrganizationConsentPolicy(payload);

                    if (tosPolicy.use_external_link) {
                        this.openExternalLink(this.tos_policy.external_link_url);

                        return;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        async openPrivacyPolicy() {
            if (this.privacy_policy) {
                this.openExternalLink(this.privacy_policy.external_link_url);

                return;
            } else {
                try {
                    const payload = { organization: this.orgId.data, section: 'privacy' };
                    const privacyPolicy = await this.retrieveOrganizationConsentPolicy(payload);

                    if (privacyPolicy.use_external_link) {
                        this.openExternalLink(this.privacy_policy.external_link_url);

                        return;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        async initIntakeTemplate() {
            IntakeService.clearSignupStorage();

            if (this.product_template_id) {
                const productIntake = await this.getProductIntake({ product_template_id: this.product_template_id });

                if (productIntake) {
                    this.signupConfig = newSignupConfigTransorm(productIntake, this.$route.params);
                }
            } else {
                await this.getGlobalIntake({ organization: this.orgId.data });
                window.localStorage.setItem('intake_template', JSON.stringify(this.globalIntake.data));

                this.signupConfig = newSignupConfigTransorm(this.globalIntake.data, this.$route.params);
            }
        },
        onSetProductTemplateStorage(productId, type) {
            localStorage.setItem('zp_product_template', JSON.stringify({
                id: productId,
                type,
            }));
        },
        async initIntakeSignup(user) {
            if (!this.signupConfig) {
                const intake = await this.getGlobalIntake({
                    organization: this.orgId.data,
                });

                this.signupConfig = newSignupConfigTransorm(this.globalIntake.data, this.$route.params);
            }

            if (this.signupConfig.intake.hasIntake) {
                const payload = {
                    templateId: this.signupConfig.intake.id,
                    userId: user._id,
                    pharmacy_verification: true,
                };
                const intake = await this.createIntakeInstance(payload);

                LogRocket.track('IntakeStartedSuccess');
                this.onSetProductTemplateStorage(this.signupConfig.product.default, 'medication');
                await this.$router.push({ path: '/consultation/hiw/' + intake.user + '/' + intake._id + '/question/none' });
            } else {
                await this.$router.push('/signup/profile');
            }
        },

        async initCheckoutSignup() {
            this.onSetProductTemplateStorage(this.product_template_id, this.product_type);

            if (this.product_type === PRODUCT_TYPES.lab) {
                await this.$router.push({ path: '/signup/lab-visit' });
            } else if (this.product_type === PRODUCT_TYPES.supplement) {
                await this.$router.push({ path: `/signup/protocol-preference/${this.product_template_id}` });
            }
        },
        async onSignupSubmit() {
            this.submitted = true;

            //sets the $dirty flag of the model and all of its children to true recursively
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const payload = {
                first_name: formatName(this.userObject.first_name),
                last_name: formatName(this.userObject.last_name),
                email: this.userObject.email.toLowerCase(),
                password: this.userObject.password,
            };

            const gclidCookie = getSignupGclid();

            if (gclidCookie) {
                payload.gclid = gclidCookie;
            }

            try {
                const { data: user } = await this.createUser(payload);

                this.initializeUserStore();
                this.initializeAuthUserStore();

                LogRocket.identify(user._id, {
                    name: `${user.first_name} ${user.last_name}`,
                    email: `${user.email}`,
                });
                analytics.identify(user._id, {
                    birthday: user.birthday,
                    weight: user.weight,
                    height: user.height,
                    email: user.email,
                    sex: user.sex,
                    organization: user.organization,
                    complete_intake: user.complete_intake,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    mobile_verified: user.mobile_verified,
                    requires_intake: user.requires_intake,
                    verified: user.verified,
                });

                analytics.track(analyticsActions.signup);
                analytics.track(analyticsActions.session_started);

                this.$gtag.event('new_signup',
                    {
                        event_category: 'documentation',
                        event_label: 'New User Sign Up',
                        value: 1,
                        id: user._id,
                    },
                );

                if ([PRODUCT_TYPES.lab, PRODUCT_TYPES.supplement].includes(this.product_type)) {
                    await this.initCheckoutSignup();
                } else {
                    await this.initIntakeSignup(user);
                }

            } catch (error) {
                console.error('An error occurred during Sign Up');
                this.$v.userObject.email.$reset();
                throw error;
            }
        },
    },
    computed: {
        ...mapState({
            duplicate: state => state.authenticate.duplicate,
            user: state => state.authenticate.user,
            organizationSettings: state => state.organizationSettings,
            globalIntake: state => state.intake.globalIntake,
            orgId: state => state.orgId,
            consent_policy: state => state.consent_policy,
            privacy_policy: state => state.privacy_policy,
            tos_policy: state => state.tos_policy,
            policy_side_panel: state => state.policy_side_panel,
            public_product: state => state.products.public_product,
        }),
    },
};

</script>

<style lang="scss">
@import "@/assets/scss/_colors.scss";

.signup-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    border-top: 1px solid $border-faint;
    border-bottom: 1px solid $border-faint;
    padding: 1.125rem 0 2rem;
    margin: 0 auto;

    &__login {
        color: $charcoal;
    }

    &__input {
        display: flex;
        flex-direction: column;
        gap: 0.8125rem;
        padding: 1.5rem 0;

        &__row {
            display: flex;
            gap: 0.8125rem;
        }

        &__error {
            display: inline-block;
            color: $errors;
            font-family: "Helvetica Now Text", serif;
            font-size: 0.75rem;
            font-weight: 500;
            padding-top: 0.125rem;

            input {
                border-color: $errors !important;
            }
        }
    }

    &__checkbox {
        padding: 0 0 2rem;
    }

    button {
        span {
            font-family: 'Helvetica Now Text', serif;
            font-size: 0.875rem;
            letter-spacing: 0.0875rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
</style>
