<template>
    <ul class="co-breakdown">
        <li v-if="isShipping" class="co-breakdown__item">
            <span>Shipping</span>
            <span class="co-breakdown__charcoal strong">
                Free
            </span>
        </li>
        <li v-if="formattedSubTotal" class="co-breakdown__item">
            <span>Sub-Total</span>
            <span class="strong">
                {{ formattedSubTotal }}
            </span>
        </li>
        <li v-if="formattedSavings" class="co-breakdown__item co-breakdown__saving">
            <span>Savings</span>
            <span class="strong">
                {{ formattedSavings }}
            </span>
        </li>
        <li v-if="formattedSpecialDiscount" class="co-breakdown__item co-breakdown__saving">
            <span>Special Discount</span>
            <span class="strong">
                {{ formattedSpecialDiscount }}
            </span>
        </li>
        <li class="co-breakdown__total">
            <span>Total Due Now</span>
            <span>
                {{ formattedTotal }}
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'PriceBreakdown',
    props: {
        price: {
            type: Object,
            required: true,
            default: () => ({
                subTotal: 0,
                savings: 0,
                total: 0,
                specialDiscount: 0,
            }),
        },
        isShipping: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        formattedSubTotal() {
            return this.price?.subTotal ? `$${this.price?.subTotal.toFixed(2)}` : null;
        },
        formattedSavings() {
            return this.price?.savings && this.price.savings > 0 ? `$${this.price.savings.toFixed(2)}` : null;
        },
        formattedTotal() {
            return `$${(this.price.total || 0).toFixed(2)}`;
        },
        formattedSpecialDiscount() {
            return this.price?.specialDiscount ? `$${this.price?.specialDiscount.toFixed(2)}` : null;
        },
    },
};
</script>
