export const getProductsForCoupon = (productTemplates) => {
    return productTemplates.reduce((acc, productTemplate) => {
        if (productTemplate.selected_configuration?.treatment_properties?.product) {
            acc.push({
                quantity: productTemplate.selected_configuration.treatment_properties.quantity,
                id: productTemplate.selected_configuration.treatment_properties.product,
            });
        }

        return acc;
    }, []);
};
