<template>
    <h-sidebar
        :open="active"
        badge_preset="green"
        badge_label="Reschedule"
        badge_size="small"
        @handleClose="on_close"
    >
        <transition name="slide-fade">
            <div v-if="view !== 'success'">
                <div class="subscription-management-modal__header">
                    <h1 class="subscription-management-modal__title">
                        See You Soon
                    </h1>
                    <h2 class="subscription-management-modal__subtitle">
                        Select the date you would like to reschedule your subscription
                    </h2>
                    <p class="subscription-management-modal__description">
                        Your treatment will ship and resume on the date selected.
                    </p>
                </div>
                <div v-if="!active_prescriptions.loading && active_prescriptions.data" class="snooze-modal__calendar">
                    <date-picker
                        :inline="true"
                        :value="default_date"
                        calendar-class="ppe-calendar"
                        :disabled-dates="disabled_dates"
                        @selected="on_date_selected"
                    ></date-picker>
                </div>
                <div v-if="selected_date?.type === 'normal'" class="snooze-modal__dates">
                    <p>
                        <strong>
                            Your treatment will be scheduled to ship on {{ selected_date.date }}.
                        </strong>
                    </p>
                </div>
                <div v-if="selected_date?.type === 'overdue'" class="snooze-modal__dates snooze-modal__dates--warning">
                    <p>
                        You’ve selected your treatment to be rescheduled on <strong>{{ selected_date.date }}</strong>, but <strong>your prescription will have expired by that date</strong>.
                    </p>
                    <br />
                    <p>
                        We can renew your prescription at no additional cost. Would you like us to proceed with the renewal?
                    </p>
                </div>
                <div class="snooze-modal__footer">
                    <HButtonV2
                        size="large"
                        fullWidth
                        variant="secondary"
                        @click="on_reschedule"
                    >
                        <span v-if="selected_date?.type === 'overdue'">Continue</span>
                        <span v-else>Submit</span>
                    </HButtonV2>
                    <HButtonV2
                        v-if="selected_date?.type === 'overdue'"
                        size="large"
                        fullWidth
                        variant="tertiary"
                        @click="on_close"
                    >
                        Cancel
                    </HButtonV2>
                </div>
            </div>
        </transition>
        <transition name="slide-fade">
            <div v-if="view === 'success'" class="subscription-modal__body">
                <SubscriptionSuccessModalSlide />
                <HButtonV2
                    size="large"
                    fullWidth
                    variant="secondary"
                    @click="on_close"
                >
                    Close
                </HButtonV2>
            </div>
        </transition>
    </h-sidebar>
</template>
<script>

import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { mapActions, mapMutations, mapState } from 'vuex';

import SubscriptionSuccessModalSlide from '@/components/Dashboard/Modals/SubscriptionSuccessModalSlide.vue';
import HButtonV2 from '@/components/Shared/HButtonV2';
import HSidebar from '@/components/Shared/HSidebar.vue';
import { getShipmentDate } from '@/helpers/subscription';

export default {
    name: 'SubscriptionSnoozeModal',
    components: {
        DatePicker: Datepicker,
        HSidebar,
        HButtonV2,
        SubscriptionSuccessModalSlide,
    },
    props: {
        subscription: {
            type: Object,
            required: true,
        },
        vendor: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            view: 'default',
            disabled_dates: {
                to: new Date(),
                from: new Date(),
                dates: [],
            },
            default_date: null,
        }
    },
    async created() {

        try {
            const payload = {
                patient: this.user._id,
                subscription_id: this.subscription._id,
                prescription_id: this.subscription.prescriptions[0],
            }
            await this.get_active_prescription(payload);
            this.disabled_dates.to = moment().endOf('day').toDate();
            this.disabled_dates.from = moment().endOf('day').add(1, 'year').toDate();
            const currentShipmentDate = getShipmentDate(this.vendor);

            if (currentShipmentDate) {
                this.disabled_dates.dates = [new Date(currentShipmentDate * 1000)];
            }
            this.default_date = new Date(currentShipmentDate ? currentShipmentDate * 1000 : new Date());
            this.default_date = moment(this.default_date).add(1, 'day').toDate();
        } catch (e) {
              console.log('created() error', e);
        }

    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            active_prescriptions: state => state.prescriptions.active_prescription,
            button_text: state => state.subscriptions.buttons,
            update_subscription_billing_cycle: state => state.subscriptions.update_subscription_billing_cycle,
        }),
        selected_date() {
            if (!this.default_date) return null;

            const selectedDate = moment(this.default_date);

            const result = {
                type: 'normal',
                date: selectedDate.format('MM/DD/YYYY'),
            };

            if (selectedDate.isAfter(moment.unix(this.vendor.current_period_end))) {
                result.type = 'overdue';
            }

            return result;
        },
    },
    methods: {
        ...mapActions({
            get_active_prescription: 'prescriptions/getActivePrescription',
            update_billing_cycle: 'subscriptions/updateBillingCycle',
            get_subscriptions: 'subscriptions/getSubscriptions',
        }),
        ...mapMutations({
            set_button: 'subscriptions/setButtonText'
        }),
        on_close() {
            this.$emit('handleClose', true);
        },
        on_date_selected(date) {
            this.default_date = date;
        },
        async on_reschedule() {
            const date = new Date(this.default_date).getTime();
            try {
                this.set_button({ id: 'reschedule', value: 'Submitting...' });

                await this.update_billing_cycle({
                    patient: this.user._id,
                    subscriptionId: this.subscription._id,
                    newTime: date
                });
                await this.get_subscriptions({
                    patient: this.user._id
                });
                this.set_button({id: 'reschedule', value: 'Reschedule'});
                this.$emit('onRescheduleSuccess');

                this.view = 'success';
            } catch (e) {
                console.log('Error on_reschedule', e);
            }
        }
    },
};

</script>
<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';
@import "@/assets/scss/patient-portal/calendar.scss";


.subscription-modal {
    &__cal {
        margin-top: 2rem;
    }

    &__loading {
        height: 459px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.snooze-modal {
    .modal-footer__container {
        margin-top: 2rem;
        padding: 0;
    }

    &__dates {
        margin-top: 2rem;
        padding: 28px 22px;
        border-radius: $radius;
        background-color: $gray-75;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include caption;

        & p {
            margin: 0;

            & strong {
                font-weight: 700;
            }
        }

        &--warning {
            background-color: #FFE4DE;
        }
    }

    &__header {

    }

    &__calendar {
        margin-top: 48px;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-top: 48px;
    }
}
</style>
