<template>
<div class="intake-wrapper">
    <div v-if="intake_instance_response.data" class="intake-container">
        <h-progress-bar :total_steps="currentIntakeInstance.data.total_steps" :current_step="step + 1"/>
        <transition :name="transition_name" mode="out-in">
                <checkbox
                    v-if="intake_instance_response.data.question.type === 'checkbox' && childDataLoaded"
                    :loaded="childDataLoaded"
                    :step="step"
                    :currentIntakeTemplate="currentIntakeTemplate"
                />
                <radio-single
                    v-if="intake_instance_response.data.question.type === 'radio' && childDataLoaded"
                    :loaded="childDataLoaded"
                    :step="step"
                    :currentIntakeTemplate="currentIntakeTemplate"
                />
                <long-text
                    v-if="intake_instance_response.data.question.type === 'long-text' && childDataLoaded"
                    :loaded="childDataLoaded"
                    :step="step"
                    :currentIntakeTemplate="currentIntakeTemplate"
                />
                <short-text
                    v-if="intake_instance_response.data.question.type === 'short-text' && childDataLoaded"
                    :loaded="childDataLoaded"
                    :step="step"
                    :currentIntakeTemplate="currentIntakeTemplate"
                />
            </transition>
    </div>
</div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'
import {mapState} from 'vuex'

//components
import Checkbox from '@/components/intake/question_types/Checkbox.vue';
import RadioSingle from '@/components/intake/question_types/RadioSingle.vue';
import LongText from '@/components/intake/question_types/LongText.vue';
import ShortText from '@/components/intake/question_types/ShortText.vue'
import { analytics, analyticsActions } from '../../helpers/analytics';
import HProgressBar from '../Shared/HProgressBar.vue';

export default {
    name: 'IntakeQuestions',
    components: {
        Checkbox: Checkbox,
        RadioSingle: RadioSingle,
        LongText: LongText,
        ShortText: ShortText,
        HProgressBar,
    },
    props: {
        currentIntakeTemplate: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            childDataLoaded: true,
            transition_name: null,
        }
    },
    computed: {
        ...mapState({
            intake_id: state => state.intake.intake_id,
            states: state => state.states.states,
            user: state => state.user.user,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            intake_instance_response: state => state.intake.intake_instance_response,
            intake_instance_response_data: state => state.intake.intake_instance_response && state.intake.intake_instance_response.data
        }),
        step() {
            return Number(this.$route.query.step);
        }
    },
    methods: {
        ...mapMutations({
            setIntakeInstanceResponse: 'intake/setIntakeInstanceResponse',
            setStep: 'intake/setStep',
        }),
        ...mapActions({
            retrieveIntakeResponseById: 'intake/retrieveIntakeResponseById',
            getIntakeTemplate: 'intake/getIntakeTemplate'
        })
    },
    created() {
        this.$nextTick(() => {
            if (!this.currentIntakeTemplate) {
                this.getIntakeTemplate({ templateId: this.currentIntakeInstance.data.intake_template._id }).then(response => {
                    window.localStorage.setItem('intake_template', JSON.stringify(response));
                });
            }
        })

        const vm = this;
        vm.childDataLoaded = false;

        const payload = {
            orgId: vm.user.organization,
            intakeId: vm.$route.params.intakeId,
            questionId: vm.$route.params.questionId,
            userId: vm.user._id
        }
        vm.retrieveIntakeResponseById(payload).then((response) => {
            vm.childDataLoaded = true;
            vm.setStep(response._id);
        }).catch((err) => {
            console.log(err);
        })
        if (this.step === 0) {
            this.transition_name = 'slide-forward';
        } else if (this.step === this.currentIntakeInstance.data.total_steps - 1){
            this.transition_name = 'slide-backward';
        }
    },
    watch: {
        intake_instance_response_data(to) {
            analytics.track(analyticsActions.intake_step_opened, {
                question_id: this.$route.params.questionId,
                type: to.question.type,
                intake_id: this.$route.params.intakeId,
            })
        },
        step(to, from) {
            analytics.track(analyticsActions.intake_step_changed, {
                question_id: this.$route.params.questionId,
                type: this.intake_instance_response.data.question.type,
                intake_id: this.$route.params.intakeId,
                from,
                to,
            })
            if (to > from) {
                this.transition_name = 'slide-forward';
            } else {
                this.transition_name = 'slide-backward';
            }
        },
        $route(to, from) {
            const vm = this;
            vm.childDataLoaded = false;
            if (to.params.section === 'intake') {
                const payload = {
                    orgId: vm.user.organization,
                    intakeId: vm.$route.params.intakeId,
                    questionId: to.params.questionId,
                    userId: vm.user._id
                }
                vm.retrieveIntakeResponseById(payload).then((response) => {
                    vm.childDataLoaded = true;
                }).catch((err) => {
                    console.log(err);
                })

            }
        }
    }
}

</script>

<style lang="scss">

.slide-forward-enter {
    opacity: 0;
    transform: translateX(100%);
}

.slide-forward-leave-to,
.slide-backward-leave-to {
    opacity: 0;
}

.slide-forward-enter-active,
.slide-forward-leave-active,
.slide-backward-enter-active,
.slide-backward-leave-active  {
    transition: 0.2s ease-out;
}

.slide-backward-enter {
    opacity: 0;
    transform: translateX(-100%);
}

</style>
