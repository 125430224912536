<template>
    <div class="input-white-container mb2r">
        <div class="orders-top-container">
            <div class="orders-item-row">
                <ul class="orders-item-list-columns">
                    <li v-for="product in products" v-if="order && products" class="order-item">
                        <div class="product-display-container">
                            <div
                                :style="{'background-image': `url(${product.product_template.product_images[0]})`}"
                                class="order-item-product-image"
                            >
                            </div>
                            <h3 class="progress-step-label cereal-category-label cap">
                                {{ product.product_template.product_name }}
                            </h3>
                            <p v-if="product.patient_instructions" class="progress-step-status-label cereal-text cap">
                                {{ product.patient_instructions }}
                            </p>

                            <p class="progress-step-status-label cereal-text cap">
                                <span
                                    class="progress-step-label cereal-category-label-emp"
                                >
                                    ${{ product.pricing.total_discount.toFixed(2) }}
                                </span>
                                <span v-if="product.product.pricing.recurring.is_recurring"> / {{ product.product.pricing.recurring.interval_count }} Month{{product.product.pricing.recurring.interval_count > 1 ? 's' : ''}}</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="web-hidden vertical">
            <OrderProgressLine :is_current="is_current" :order="order" :subscription="subscription"/>
        </div>
        <div class="horizontal tablet-hidden">
            <OrderProgressLine :is_current="is_current" :order="order" :subscription="subscription"/>
        </div>
        <div class="orders-more-details-container">
            <div class="order-md-container">
                <div class="order-detail-progress">
                    <div class="order-detail-icon-section">
                        <svg aria-hidden="true" focusable="false" role="presentation"
                             style="display: block; height: 22px; width: 22px; fill: currentcolor;"
                             viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z"></path>
                        </svg>
                    </div>
                    <div class="order-detail-information-section b-step-container">
                        <p class="progress-step-label cereal-category-label">Shipping Address</p>
                        <div>
                            <p class="progress-step-status-label cereal-text">
                                {{ order.shipping_address.first_name }}
                                {{ order.shipping_address.last_name }}</p>
                            <p class="progress-step-status-label cereal-text">
                                {{ order.shipping_address.street_address }}</p>
                            <p v-if="order.shipping_address.apt_suite" class="progress-step-status-label cereal-text">
                                {{ order.shipping_address.apt_suite || 'Apt 3' }}</p>
                            <p class="progress-step-status-label cereal-text">
                                {{ order.shipping_address.city  }},
                                {{ order.shipping_address.state }}
                                {{ order.shipping_address.zip_code}}</p>
                            <p class="progress-step-status-label cereal-text">
                                {{ order.shipping_address.country  }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-md-container">
                <div class="order-detail-progress">
                    <div class="order-detail-icon-section">
                        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.5 9.96997V19C19.5 21 19 22 16.5 22H7.5C5 22 4.5 21 4.5 19V9.96997C4.66 9.98997 4.83 9.99997 5 9.99997H19C19.17 9.99997 19.34 9.98997 19.5 9.96997Z"
                                fill="#292D32"
                                opacity="0.4"/>
                            <path
                                d="M22 5V7C22 8.83 21.17 9.82 19.5 9.97C19.34 9.99 19.17 10 19 10H5C4.83 10 4.66 9.99 4.5 9.97C2.83 9.82 2 8.83 2 7V5C2 3 3 2 5 2H19C21 2 22 3 22 5Z"
                                fill="#292D32"/>
                            <path
                                d="M13.82 14.75H10.18C9.76999 14.75 9.42999 14.41 9.42999 14C9.42999 13.59 9.76999 13.25 10.18 13.25H13.82C14.23 13.25 14.57 13.59 14.57 14C14.57 14.41 14.23 14.75 13.82 14.75Z"
                                fill="#292D32"/>
                        </svg>
                    </div>
                    <div class="order-detail-information-section b-step-container">
                        <p class="progress-step-label cereal-category-label">Prescriptions</p>
                        <div v-if="!is_current">
                            <div v-if="order.prescriptions.length > 0">
                                <div v-for="(prescription, index) in order.prescriptions" :key="index">
                                    <p class="progress-step-status-label cereal-text">
                                        {{ index + 1 }}. {{ prescription.medication.name }}
                                    </p>
                                    <p class="progress-step-status-label cereal-text">Prescriber:
                                        {{ prescription.prescriber_name || 'Awaiting Assignment' }}</p>
                                </div>
                            </div>
                            <div v-else>
                                There are no medications for this order.
                            </div>
                        </div>

                        <div v-else>

                            <div v-if="subscription.prescriptions.length > 0">
                                <div v-for="(prescription, index) in subscription.prescriptions" :key="index">
                                    <p class="progress-step-status-label cereal-text">
                                        {{ index + 1 }}. {{ prescription.medication.name }}
                                    </p>
                                    <p class="progress-step-status-label cereal-text">Prescriber:
                                        {{ prescription.prescriber_name }}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import moment from 'moment';
import StepBubble from '@/components/Components/StepBubble.vue';
import OrderProgressLine from '@/components/Dashboard/Subcomponents/OrderProgressLine.vue';
import ProductService from '@/services/Product.js';


export default {
    name: 'orders_subcomponent',
    components: {
        StepBubble: StepBubble,
        OrderProgressLine: OrderProgressLine
    },
    props: ['order', 'subscription', 'is_current'],
    data() {
        return {
            products: null,
            invoice: null
        }
    },
    async created() {
        if (this.order.stripeInvoiceId && this.order.stripeInvoiceId.length) {
            await this.getOrderInvoice(this.order.stripeInvoiceId[0]);
        }
        this.products = await this.get_products(this.order);

    },
    methods: {
        ...mapActions({
            get_product_template: "products/getProductTemplate",
            get_invoice: 'subscriptions/getInvoice'
        }),
        async getOrderInvoice(order_invoice) {
            this.invoice = await this.get_invoice({
                invoice: order_invoice,
                type: 'stripe'
            })
            this.$forceUpdate();

        },
        closeResults() {
            this.$modal.hide('results');
        },
        generateBody(product, productTemplate, plan, prescriptions) {
            let planProperties = product.plan_properties;

            const prescription = prescriptions.find(script => {
                const productId = script.product._id ? script.product._id : script.product;

                return productId === product._id;
            });

            const count = product.pricing.interval_count;

            const frequency = productTemplate.dosage_frequencies
                .find(f => f.frequency.label === planProperties.dosage_frequency.label && f.frequency.count === planProperties.dosage_frequency.count);
            let price = product;

            if (this.invoice) {
                const activePrice = this.invoice.lines.data.find(line => line.price.product === product.stripe_product_id);

                if (activePrice) {
                    price = {
                        pricing: activePrice.price,
                        plan_properties: product.plan_properties,
                    };
                }
            }

            const pricing = ProductService.generatePrice({
                product: price,
                frequency,
                membershipPrice: plan.membership_price,
                units: (plan.quantity / count) ?? planProperties.units_per_month,
                specialDiscountByProductId: plan.price?.discount ? { [plan._id]: plan.price } : {},
            });

            return {
                product,
                membership_price: plan.membership_price,
                custom_quantity: plan.quantity,
                patient_instructions: prescription ? prescription.medication.patient_instructions : '',
                product_template: productTemplate,
                pricing,
            };
        },
        async get_products_from_subscription(sub) {
            let products = [];
            products = products.concat(sub.product_plans.map(async plan => {
                const productTemplate = await this.get_product_template({
                    product_template_id: plan.product_template
                });

                return this.generateBody(plan.product, productTemplate, plan, sub.prescriptions);
            }));
            return await Promise.all(products)

        },
        /*
            Returns array that
            [{
                product: {}
                custom_quantity: {}
                membership_price: {}
                patient_instructions: ""
            },...]
         */
        async get_products(order) {
            if (this.subscription?.product_plans) {
                return await this.get_products_from_subscription(this.subscription);
            }

            return order.products.map(orderProduct => {
                return this.generateBody(orderProduct.product, orderProduct.product.product_template, orderProduct, order.prescriptions);
            });
        }
    },
    computed: {
        userData: state => state.user.user,
        orders: state => state.orders.orders,
    },
    filters: {
        date: function (date) {
            return moment(date).format('MMMM Do, YYYY');
        },
        date_unix: (date) => {
            return moment.unix(date).format('MMMM Do, YYYY');
        }
    },
}

</script>
<style lang="scss">
.order-item {
    h3.progress-step-label {
        white-space: nowrap;
        overflow: hidden;
        max-width: 140px;
        text-overflow: ellipsis;
    }
}
</style>

