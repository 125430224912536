import { deprecatedProducts } from '@/constants/deprecatedProducts';

export default {
    DEFAULT_PREFERENCE_SELECTION: 'starter',
    selectProduct(products, filter) {
        return products.find(product => {
            return product.pricing.recurring.interval_count === filter.duration && product.plan_properties.dosage_frequency.label === filter.frequency.frequency.label;
        });
    },
    selectProductByFrequency(products, filter) {
        return products.find(product => {
            return product.plan_properties.dosage_frequency.label === filter.frequency.frequency.label;
        });
    },

    hasReview(treatmentSettings) {
        const selectedConfigurations = Object.values(treatmentSettings);

        return !!selectedConfigurations.find(s => s.recommendation_requested);
    },

    generatePrice({ product, frequency, membershipPrice, units, specialDiscountByProductId = {} }) {
        const productId = product.pricing.product ? product.pricing.product : product.stripe_product_id;

        const deprecatedProduct = deprecatedProducts.find(p => productId === p);
        let price = product.pricing.unit_amount / 100;

        let monthlyUnits = units;

        if (!units) {
            monthlyUnits = frequency?.has_custom_quantity ? frequency.custom_quantity.default : product.plan_properties.monthly_units;
        }

        if (deprecatedProduct) {
            price = price * monthlyUnits;
        }

        const specialDiscount = specialDiscountByProductId[product._id || productId];

        const productPricing = {
            product: frequency?.has_custom_quantity ? (monthlyUnits * price) : price,
            product_savings: product.pricing.discount ? product.pricing.discount / 100 : 0,
            membership: membershipPrice ? membershipPrice.unit_amount / 100 : 0,
            membership_savings: membershipPrice && membershipPrice.discount ? membershipPrice.discount / 100 : 0,
            specialDiscount: specialDiscount ? specialDiscount.discount / 100 : 0,
        };

        productPricing['total'] = productPricing.product + productPricing.product_savings + productPricing.membership + productPricing.membership_savings;

        productPricing['total_discount'] = productPricing.total - (productPricing.product_savings + productPricing.membership_savings + productPricing.specialDiscount);

        return productPricing;
    },

    generateTotalPrice(durations) {
        const pricing = {
            total: 0,
            total_discount: 0,
            product_savings: 0,
            membership_savings: 0,
            total_savings: 0,
            product: 0,
            membership: 0,
        };

        for (let i = 0; i < durations.length; i++) {
            if (durations[0].prices) {
                pricing.months = durations[i].months;
                pricing.total += durations[i].prices.total;
                pricing.total_discount += durations[i].prices.total_discount;
                pricing.product_savings += durations[i].prices.product_savings;
                pricing.membership_savings += durations[i].prices.membership_savings;
                pricing.product += durations[i].prices.product;
                pricing.membership += durations[i].prices.membership;
            }
        }

        return pricing;
    },

    getPlanOptions(products, productTemplate, specialDiscountByProductId = {}) {
        if (!products?.length || !productTemplate) return [];

        const sortedProducts = [...products].sort((a, b) => a.pricing.interval_count - b.pricing.interval_count);

        if (!sortedProducts.length) {
            return [];
        }

        const baseProduct = sortedProducts[0];

        const basePrice = baseProduct ? this.generatePrice({
            product: baseProduct,
            frequency: productTemplate.dosage_frequencies[0],
            specialDiscountByProductId,
        }).total_discount : 0;

        return sortedProducts.map((product) => {
            const intervalCount = product.pricing.interval_count;

            const pricing = this.generatePrice({
                product,
                frequency: productTemplate.dosage_frequencies[0],
                specialDiscountByProductId,
            });

            const totalBasePrice = basePrice * intervalCount;

            return {
                id: product.id,
                duration: intervalCount,
                total: pricing.total,
                totalDiscount: pricing.total_discount,
                price: pricing.total_discount,
                savings: totalBasePrice - pricing.total_discount,
                highlight: product.pricing.default,
                type: product.pricing.type,
                specialDiscount: pricing.specialDiscount,
            };
        });
    },
};
