<template>
    <div v-if="bannerType?.type && bannerType.type !== 'none'" class="conversation-banner__wrapper" data-test="chat-banner">
        <img
            class="conversation-banner__icon"
            width="20px"
            src="@/assets/icons/messenger/info.svg"
            alt=""
        />
        <p v-if="bannerType.type === 'holiday'" class="conversation-banner__content">
            Due to the holiday, our offices are operating with essential staff only
            from 9 AM - 4 PM. Response times may be slower than usual, but we’ll get
            back to you as soon as possible. Please feel free to explore our
            <a
                class="strong"
                target="blank"
                rel="noopener noreferrer nofollow"
                href="https://healthspan.zendesk.com/hc/en-us"
            >resources</a> and thank you for your patience!
        </p>
        <p v-if="bannerType.type === 'after-hours'" class="conversation-banner__content">
            Our offices are currently closed.
            Regular business hours are 9 AM - 4 PM PT.
            Please feel free to explore our
            <a
                class="strong"
                target="blank"
                rel="noopener noreferrer nofollow"
                href="https://healthspan.zendesk.com/hc/en-us"
            >resources</a>, and we will get back to you as soon as possible during our next business day.
        </p>
        <img
            class="conversation-banner__icon conversation-banner__icon--close"
            width="20px"
            src="@/assets/icons/messenger/close.svg"
            alt=""
            @click="closeBanner"
        />
    </div>
</template>

<script>
import { getMessengerBannerType } from '@/domains/messenger/utils/getMessengerBannerType';

export default {
    name: 'BannerMessage',
    data() {
        return {
            bannerType: getMessengerBannerType(Date.now()),
        };
    },
    methods: {
        closeBanner() {
            this.bannerType = {
                ...this.bannerType,
                type: 'none',
            };
        },
    },
};
</script>
