<template>
<form :id="intake_instance_response.data._id" @submit.prevent="checkForm()" class="intake-question">
    <div class="intake-question__header">
        <h2>{{ intake_instance_response.data.question.label }}</h2>
            <p v-if="intake_instance_response.data.question.description_text" class="intake-question__description strong">
            {{ intake_instance_response.data.question.description_text }}</p>
    </div>

    <!--if patient says yes to the medical conditions-->
    <div v-if="loaded" class="question-container">
        <div class="intake-question__textarea">
            <textarea v-model="free_response" class="textarea-questionnaire-style"></textarea>
        </div>
    </div>

    <div class="intake-button">
            <h-button
                label="Continue"
                :disabled="!checkTrue"
                :loading="button_submitting"
                variant="primary"
                full_width
                large
                :handle-click="() => {}"
                data-test="continue-button"
            />
    </div>
    <div class="intake-certification">
        <h-certification/>
    </div>
</form>

</template>


<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import HCertification from '@/components/Shared/HCertification.vue';
import { newSignupConfigTransorm } from '@/domains/intake/makeIntakeTemplateConfig';

import HButton from '../../Shared/HButton.vue';

export default {
    name: 'LongText',
    data() {
        return {
            button_submitting: false,
            free_response: '',
        }
    },
    props: {
        step: [String, Number],
        loaded: Boolean,
        currentIntakeTemplate: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        HButton,
        HCertification
    },
    methods: {
        checkForm() {
            const vm = this;
            if (vm.free_response.length === 0) {
                return;
            }
            vm.button_submitting = true;
            const patient_response = {free_format_response: vm.free_response};

            try {
                let payload = {
                    response_id: vm.intake_instance_response.data._id,
                    user_id: vm.user._id,
                    intake_id: vm.$route.params.intakeId,
                    response: patient_response
                }
                vm.updateIntakeResponse(payload);
            } catch (error) {
                console.log('updateIntakeResponseError', error);
                vm.button_submitting = false;
                throw error;
            }

            const signupConfig = newSignupConfigTransorm(vm.currentIntakeTemplate);

            setTimeout(() => {
                vm.routerPush({ step: vm.step, signupConfig });
                vm.free_response = '';
                vm.button_submitting = false;
            }, 150)
        },
        ...mapActions({
            updateIntakeResponse: 'intake/updateIntakeResponse',
        }),
        ...mapMutations({
            routerPush: 'intake/routerPush'
        })
    },
    computed: {
        ...mapGetters({
            totalSteps: 'intake/totalSteps'
        }),
        ...mapState({
            intake_id: state => state.intake.intake_id,
            states: state => state.states.states,
            user: state => state.user.user,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            intake_instance_response: state => state.intake.intake_instance_response
        }),
        checkTrue() {
            const vm = this;
            return (vm.free_response.length > 0); //user response
        },
    },
    created() {
        this.free_response = this.intake_instance_response.data.free_format_response || '';
    }

}
</script>
