<template>
    <div v-if="subscription" class="subscription-item__container">
        <div class="subscription-item__wrapper">
            <ul v-if="subscription.product_plans.length" class="subscription-item__showcase">
                <li class="subscription-item__showcase-item" :style="{'background-image': `url(${subscription.product_plans[0].product.images[0]})`}"></li>
            </ul>
            <div class="subscription-item__details">
                <div class="subscription-item__status-tabs">
                    <h-badge :label="statusInfo.label" :preset="statusInfo.color" small />
                    <h-badge v-if="subscription.status === 'awaiting_physician_review'" label="Pending" preset="white" small />
                    <h-badge v-if="get_schedule_item(vendor) && subscription.status === 'active'" :label="shipmentDate" preset="white" small />
                </div>
                <div class="subscription-item__title">
                    <span><strong>{{ subscription.plan_name }}</strong></span>
                </div>
                <div v-if="subscriptionItem.status === 'paused'" class="subscription-item__note">
                    Your subscription is currently paused, you can resume at anytime.
                </div>
                <div class="subscription-item__renewal">
                    <p
                        v-if="subscription.status === 'active' && subscription.subscription_id"
                        class="subscription-item__renewal-next"
                    >
                        <strong>Next renewal on {{ (vendor.current_period_end || get_schedule_item(vendor)) | unix_date("MMM Do") }}</strong>
                    </p>
                    <p v-if="subscriptionItem.status !== 'paused'" class="subscription-item__renewal-billing">
                        Billing every {{ subscription.duration }} month{{ subscription.duration > 1 ? 's' : '' }}
                    </p>
                    <div class="subscription-item__links">
                        <a
                            v-if="latest_invoice"
                            class="subscription-item__link"
                            :href="latest_invoice.invoice_pdf"
                        >
                            Invoice
                            <img src="@/assets/images/subscription/file_download.svg" alt="download" />
                        </a>
                        <a
                            v-for="(attachment) in attachments"
                            :key="attachment.url"
                            class="subscription-item__link subscription-item__attachment"
                            :href="attachment.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-test-name="attachment-link"
                        >
                            {{ attachment.name }}
                            <img src="@/assets/images/subscription/file_download.svg" alt="download" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="subscription-item__actions">
            <HButtonV2
                v-if="(vendor && vendor.pause_collection) && subscription.status === 'paused'"
                variant="secondary"
                @click="on_resume_subscription()"
            >
                Resume
            </HButtonV2>
            <HButtonV2
                v-if="vendor && vendor.id && !vendor.pause_collection && subscription.status === 'active'"
                variant="secondary"
                @click="on_snooze_subscription()"
            >
                Reschedule
            </HButtonV2>
            <HButtonV2
                v-if="subscription.status === 'active'"
                variant="tertiary"
                @click="on_pause_subscription()"
            >
                Pause
            </HButtonV2>
            <HButtonV2
                v-if="subscription.status === 'active' || subscription.status === 'paused'"
                variant="empty"
                @click="on_cancel_subscription()"
            >
                Cancel
            </HButtonV2>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {required, minLength, between, email} from 'vuelidate/lib/validators';
import moment from 'moment';
import {getShipmentDate} from "@/helpers/subscription";
import HBadge from '@/components/Shared/HBadge.vue';
import HButtonV2 from '@/components/Shared/HButtonV2';

export default {
    name: 'subscription-item',
    components: {
        HBadge,
        HButtonV2,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        subscriptionItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            modify_subscription_modal: false,
            cancel_subscription_button: 'Cancel Subscription',
            subscription_selected: null,
            subscription: null,
            vendor: null,
            latest_invoice: null
        }
    },
    watch: {
        subscriptionItem: {
            handler() {
                this.fetchSubscriptionData();
            },
            immediate: true,
            deep: true,
        },
    },
    async created() {
        await this.fetchSubscriptionData();
    },
    methods: {
        ...mapActions({
            cancelSubscription: 'subscriptions/cancelSubscription',
            getSubscriptions: 'subscriptions/getSubscriptions',
            getProductTemplate: 'products/getProductTemplate',
            getSubscription: 'subscriptions/getSubscription',
            getInvoice: 'subscriptions/getInvoice',
        }),
        async fetchSubscriptionData() {
            let subscriptionPayload = {
                patient: this.user._id,
                subscription_id: this.id,
            };

            if (this.subscriptionItem.status !== 'awaiting_physician_review') {
                subscriptionPayload['type'] = 'stripe';
            }

            try {
                await this.getSubscription(subscriptionPayload);
                this.subscription = this.data.subscription;
            } catch (error) {
                console.error('Error fetching subscription:', error);

                return;
            }

            if ('details' in this.data) {
                this.vendor = this.data.details;
                this.latest_invoice = await this.getInvoice({
                    invoice: this.vendor.latest_invoice,
                    type: 'stripe',
                });
            }

            this.configure_plans();
        },
        on_contact() {
            this.$router.push({path: `/messages/${this.user._id}/new`})
        },
        configure_plans() {
            let product_plans = this.subscription.product_plans;
            if (product_plans.length) {
                this.subscription['plan_name'] = product_plans.length > 1 ?
                    `${product_plans[0].product_template.product_name} and ${product_plans.length - 1} more...` :  product_plans[0].product_template.product_name;
            } else {
                this.subscription['plan_name'] = this.subscription.name;
            }

            this.$forceUpdate();
        },
        on_cancel_subscription() {
            this.$emit('onSubscriptionCancelRequest', {
                vendor: this.vendor,
                subscription: this.subscription
            });
        },
        on_pause_subscription() {
            this.$emit('onSubscriptionPauseRequest', {
                vendor: this.vendor,
                subscription: this.subscription,
            });
        },
        on_resume_subscription() {
            this.$emit('onSubscriptionResumeRequest', {
                vendor: this.vendor,
                subscription: this.subscription
            })
        },
        on_snooze_subscription() {
            this.$emit('onSubscriptionSnoozeRequest', {
                vendor: this.vendor,
                subscription: this.subscription
            })
        },
        get_schedule_item(vendor) {
            return getShipmentDate(vendor);
        }
    },
    filters: {
        readable: (string) => {
            if (string) {
                let words = string.split('_');
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1); // capitalize the first letter of each word
                }
                return words.join(' ');
            } else {
                return string;
            }

        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            data: state => state.subscriptions.subscription.data,
            invoice: state => state.subscriptions.invoice.data,
        }),
        shipmentDate() {
            return `Ships on ${moment.unix(this.get_schedule_item(this.vendor)).format('MMM Do')}`;
        },
        attachments() {
            return this.subscription?.product_plans?.[0]?.product_template?.attachments || [];
        },
        statusInfo() {
            const STATUS_MAP = {
                awaiting_physician_review: {
                    label: 'Awaiting Physician Review',
                    color: 'dark-blue',
                },
                created: {
                    label: 'Created',
                    color: 'green',
                },
                incomplete: {
                    label: 'Incomplete',
                    color: 'white',
                },
                incomplete_expired: {
                    label: 'Expired',
                    color: 'white',
                },
                trialing: {
                    label: 'Trialing',
                    color: 'blue',
                },
                active: {
                    label: 'Active',
                    color: 'green',
                },
                past_due: {
                    label: 'Past Due',
                    color: 'red',
                },
                canceled: {
                    label: 'Canceled',
                    color: 'white',
                },
                unpaid: {
                    label: 'Unpaid',
                    color: 'red',
                },
                paused: {
                    label: 'Paused',
                    color: 'dark-blue',
                },
            };

            return STATUS_MAP[this.subscriptionItem.status];
        },
    },
};

</script>


<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.subscription-item {
    &__container {
        display: flex;
        align-items: center;
        padding: 3rem 0;
        background-color: #FFFFFF;
        border-radius: 12px;
        transition: all 0.3s ease;
        justify-content: space-between;
        border-bottom: 1px solid #F2F2F2;

        @media screen and (max-width: 962px){
            flex-direction: column;
        }
    }

    &__wrapper {
        display: flex;

        @media screen and (max-width: 962px){
            width: 100%;
            padding-bottom: 1rem;
        }

        @media screen and (max-width: 390px){
            flex-direction: column;
        }
    }


    &__showcase {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
    }

    &__showcase-item {
        width: 197px;
        height: 197px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 12px;
        border: 1px solid #f1f1f1;
        background-color: #f9f9f9;
    }

    &__details {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 390px){
            margin-left: 0;
            margin-top: 1rem;
        }
    }

    &__title {
        @include heading-sm;
    }

    &__actions {
        display: flex;
        flex-direction: column;

        button {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: 962px){
            width: 100%;
            margin-top: 1rem;
        }
    }

    &__sub-actions {
        font-family: 'ZP-UI', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: 0.875rem;
        font-weight: 500;
        padding-top: 0.5rem
    }

    &__status-tabs {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    &__status {
        font-size: 0.75rem;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background-color: $cool100;
        border-radius: 5px;
        font-weight: 500;

        &.pending {
            background-color: #f1f1f1;
        }

        &.active {
            background-color: $nature100;
            color: $nature600;
        }
    }

    &__renewal {
        margin-top: 12px;
        &-next {
            @include caption;
            margin-bottom: 12px;
            color: #4e5259;

            & strong {
                font-weight: 700;
            }
        }
        &-billing {
            @include caption;
            margin-bottom: 12px;
        }
    }
    &__links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 14px;
    }
    &__link {
        padding: 0.25rem 0.5rem;
        background-color: #f1f1f1;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        transition: all 0.3s ease;
        color: $neutral600;
        @include caption-sm;
        font-weight: bold;
        line-height: 1rem;

        &:hover {
            background-color: darken(#f1f1f1, 8%);
            text-decoration: none;
            color: $neutral600;
        }

        ion-icon {
            vertical-align: middle;
        }
    }
    &__attachment {
        background-color: $powder-blue-100;

        &:hover {
            background-color: darken($powder-blue-100, 8%);
        }
    }
    &__note {
        padding: 16px;
        @include caption;
        background-color: rgba(210, 228, 238, 0.3);
        border-radius: $radius;
        margin-top: 16px;
        margin-bottom: 24px;
    }
}

.subscription {
    &__product-item-list {
        list-style: none;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
    }

    &__product-item {
        display: flex;
        margin-bottom: 1.5rem;
    }

    &__product-name {
        margin-left: 1rem;
        font-family: "Sofia Semi Bold", sans-serif;
    }

    &__product-image {
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.cancel-subscription {
    &__container {
        width: 100%;
    }

    &__modal-container {
        padding: 2rem;
    }

}


</style>

