<template>
    <h-sidebar
        :open="active"
        badge_preset="green"
        badge_label="Manage"
        badge_size="small"
        @handleClose="on_close"
    >
        <transition name="slide-fade">
            <div v-if="view === 'success'" class="subscription-modal__body">
                <SubscriptionSuccessModalSlide />
                <HButtonV2
                    size="large"
                    fullWidth
                    variant="secondary"
                    :loading="pause_subscription.loading"
                    @click="on_close"
                >
                    Close
                </HButtonV2>
            </div>
        </transition>
        <transition name="slide-fade">
            <div v-if="view !== 'success'" class="subscription-modal__body">
                <div class="subscription-management-modal__header">
                    <h2 class="subscription-management-modal__title">
                        {{ modal_title }}
                    </h2>
                    <p v-if="view === 'pause'" class="subscription-management-modal__description">
                        You may resume your subscription at anytime.
                    </p>
                    <p v-if="view === 'cancel'" class="subscription-management-modal__description">
                        You can start a new subscription at anytime.
                    </p>
                    <p v-if="view === 'default'" class="subscription-management-modal__subtitle">
                        What would you prefer to do?
                    </p>
                    <p v-if="view === 'pause'" class="subscription-management-modal__caption">
                        Before we place your subscription on hold, would you mind letting us know why?
                    </p>
                    <p v-if="view === 'cancel'" class="subscription-management-modal__caption">
                        Before we cancel your subscription, would you mind letting us know why?
                    </p>
                </div>
                <div v-if="view === 'default'" class="subscription-modal__options">
                    <transition name="slide-fade">
                        <action-card
                            v-if="subscription.status !== 'paused'"
                            title="Pause my subscription"
                            description="You will not be charged when your subscription is paused and you can resume your subscription at anytime."
                            :has_icon="true"
                            icon="arrow-forward-outline"
                            @onAction="on_pause"
                        >
                        </action-card>
                    </transition>
                    <transition v-if="!pause" name="slide-fade">
                        <action-card
                            title="Cancel my subscription"
                            description="Your subscription will be permanently canceled, you can start a new subscription at anytime."
                            :has_icon="true"
                            icon="arrow-forward-outline"
                            @onAction="on_cancel_option"
                        ></action-card>
                    </transition>
                    <transition name="slide-fade">
                        <info-well
                            title="We're here to help!"
                            description="If you're having any trouble, please get in touch."
                            action_title="Message Us"
                            :has_action="true"
                            @onAction="message"
                        >
                        </info-well>
                    </transition>
                </div>
                <transition name="slide-fade">
                    <div v-if="view === 'pause'" class="subscription-modal__survey">
                        <ul class="subscription-modal__survey-list">
                            <li
                                v-for="item in pause_survey"
                                :key="item.id"
                                :class="{'active': selected_survey && selected_survey.id === item.id}"
                                @click="on_survey_item(item)"
                            >
                                <div class="hs-radio">
                                    <input
                                        :id="item.id"
                                        v-model="selected_survey"
                                        name="radio"
                                        type="radio"
                                        :value="item"
                                    >
                                    <label class="hs-radio-label" :for="item.id">{{ item.name }}</label>
                                </div>
                            </li>
                        </ul>
                        <textarea v-if="other_selected" v-model="other_description" class="subscription-modal__survey-textarea" placeholder="Please describe"></textarea>
                        <div class="subscription-modal__survey-actions">
                            <HButtonV2
                                size="large"
                                fullWidth
                                variant="secondary"
                                :loading="pause_subscription.loading"
                                @click="pause_active_subscription"
                            >
                                {{ button_text.cancel }}
                            </HButtonV2>
                            <HButtonV2
                                size="large"
                                fullWidth
                                variant="empty"
                                :loading="pause_subscription.loading"
                                @click="change_view('default')"
                            >
                                Cancel
                            </HButtonV2>
                        </div>
                    </div>
                </transition>
                <transition name="slide-fade">
                    <div v-if="view === 'cancel'" class="subscription-modal__survey">
                        <ul class="subscription-modal__survey-list">
                            <li
                                v-for="item in survey_items"
                                :key="item.id"
                                :class="{'active': selected_survey && selected_survey.id === item.id}"
                                @click="on_survey_item(item)"
                            >
                                <div class="hs-radio">
                                    <input
                                        :id="item.id"
                                        v-model="selected_survey"
                                        name="radio"
                                        type="radio"
                                        :value="item"
                                    >
                                    <label class="hs-radio-label" :for="item.id">{{ item.name }}</label>
                                </div>
                            </li>
                        </ul>
                        <textarea v-if="other_selected" v-model="other_description" class="subscription-modal__survey-textarea" placeholder="Please describe"></textarea>
                        <div class="subscription-modal__survey-actions">
                            <HButtonV2
                                size="large"
                                fullWidth
                                variant="secondary"
                                :loading="cancel_subscription.loading"
                                @click="cancel_active_subscription"
                            >
                                Submit
                            </HButtonV2>
                            <HButtonV2
                                size="large"
                                fullWidth
                                variant="empty"
                                :loading="pause_subscription.loading"
                                @click="change_view('default')"
                            >
                                Cancel
                            </HButtonV2>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </h-sidebar>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import SubscriptionSuccessModalSlide from '@/components/Dashboard/Modals/SubscriptionSuccessModalSlide.vue';
import ActionCard from '@/components/Shared/ActionCard.vue';
import HButtonV2 from '@/components/Shared/HButtonV2';
import HSidebar from '@/components/Shared/HSidebar.vue';
import InfoWell from '@/components/Shared/InfoWell';

export default {
    name: 'SubscriptionCancelModal',
    components: {
        InfoWell,
        ActionCard,
        HSidebar,
        HButtonV2,
        SubscriptionSuccessModalSlide,
    },
    props: {
        subscription: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
        pause: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            view: 'default',
            organization_data: null,
            selected_survey: null,
            other_description: null,
            other_selected: false,
            modal_title: null,
            pause_survey: [
                {
                    name: 'Not using it enough',
                    id: 'not_using',
                },
                {
                    name: 'Too expensive',
                    id: 'expensive',
                },
                {
                    name: 'Away or traveling',
                    id: 'away',
                },
                {
                    name: 'Alternative treatment',
                    id: 'alternative',
                },
                {
                    name: 'Other',
                    id: 'other',
                },
            ],
            survey_items: [
                {
                    name: 'Too expensive',
                    id: 'expensive'
                },
                {
                    name: 'Not taking medications anymore',
                    id: 'not_taking'
                },
                {
                    name: 'Medications not working for me',
                    id: 'not_working'
                },
                {
                    name: `I don't need it anymore.`,
                    id: 'not_needed'
                },
                {
                    name: 'Other',
                    id: 'other'
                }
            ]
        }
    },
    async created() {
        this.modal_title = `We'll miss you ${this.user.first_name}!`;
        this.organization_data = this.organization;
        if (!this.organization_data) {
            this.organization_data = JSON.parse(window.localStorage.getItem('zp_org_settings'));
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            organization: state => state.organization.organization,
            cancel_subscription: state => state.subscriptions.cancel_subscription,
            pause_subscription: state => state.subscriptions.pause_subscription,
            button_text: state => state.subscriptions.buttons,
        })
    },
    methods: {
        ...mapActions({
            get_organization: 'getOrganization',
            cancelSubscription: 'subscriptions/cancelSubscription',
            pauseSubscription: 'subscriptions/pauseSubscription',
        }),
        on_close() {
            this.$emit('closeModal', true);
        },
        message() {
            this.$router.push({path: `/messages/${this.user._id}/new`})
        },
        on_pause() {
            this.change_view('pause');
            this.modal_title = `See you soon ${this.user.first_name}!`;
        },
        on_cancel_option() {
            this.change_view('cancel');
        },
        change_view(view) {
            this.modal_title = `We'll miss you ${this.user.first_name}!`;
            this.view = view;
            this.$forceUpdate();
        },
        on_survey_item(item) {
            this.other_selected = false;
            this.other_description = null;
            this.selected_survey = item;

            if (item.id === 'other') {
               this.other_selected = true;
            }
        },
        async pause_active_subscription() {
            if (this.subscription) {
                const pausePayload = {
                    subscriptionId: this.subscription._id,
                };

                try {
                    await this.pauseSubscription(pausePayload);

                    this.$emit('onSuccess');
                    this.view = 'success';
                } catch (error) {
                    console.error('pause_active_subscription error', error);
                }
            }
        },
        async cancel_active_subscription() {
            if (this.subscription && (this.other_description || this.selected_survey )) {
                const cancelReason = this.other_description || this.selected_survey.name;

                const payload = {
                    patient: this.user._id,
                    subscriptionId: this.subscription._id,
                    data: {
                        cancel_reason: cancelReason,
                    },
                };

                try {
                    await this.cancelSubscription(payload);

                    this.$emit('onSuccess');
                    this.view = 'success';
                } catch (error) {
                    console.error('cancel_active_subscription error', error);
                }
            }
        },

    }
}
</script>
<style lang="scss">

@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/_type.scss";
@import "@/assets/scss/utility.scss";
@import "@/assets/scss/patient-portal/shared.scss";

.vm--modal {
    border-radius: 12px;
}



.subscription-modal {
    &__body {
        padding: 1rem;
    }

    &__subtitle {
        @include body;
        margin-bottom: 48px;
    }

    &__form {
        margin-top: 2.5rem;

        .ppe-action-card__container {
            margin-bottom: 1rem;
        }
    }

    &__survey {
        .modal-footer__container {
            margin-top: 4rem;
            padding: 0;
        }

        &-textarea {
            @include caption;

            width: 100%;
            min-height: 100px;
            border-radius: $radius;
            border: 1px solid #a7a7a7;
            resize: none;
            -webkit-appearance: none;
            appearance: none;
            transition: all 0.3s ease;
            padding: 1.25rem 1.25rem;

            &:hover, &:focus {
              border: 1px solid $border-default;
            }
        }

        &-actions {
            margin-top: 48px;
        }
    }

    &__survey-list {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 48px;

        li {
            padding: 0.5rem 0.75rem;
            border: 1px solid $border-light;
            border-radius: $radius;
            margin-bottom: 0.75rem;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover, &.active {
                border: 1px solid $border-default;
            }

            .hs-radio {
                margin-bottom: 0;
            }
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 48px;
    }


}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
