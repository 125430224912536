<template>
    <div>
        <form id="upload-id" class="verification" @submit.prevent="">
            <div class="identity__header">
                <h1>Provide a Valid ID</h1>
                <p class="h-caption text-dim-60">
                    *all documents are destroyed after account verification
                </p>
            </div>

            <div v-if="!existingImage" class="upload__container">
                <div class="upload__wrapper">
                    <div class="identity__header--small">
                        <h2>Take or upload a valid ID</h2>
                        <p class="text-dim-80">
                            Used to verify your personal information is correct.
                        </p>
                    </div>

                    <!--image has not been uploaded-->
                    <div v-if="!image" class="upload__container">
                        <div v-if="!camera_photo" class="upload__image">
                            <div class="upload__img-container">
                                <img src="@/assets/images/verification/id_icon.svg">
                            </div>
                            <div class="upload__requirements">
                                <ul>
                                    <li>Max file size 10mb</li>
                                    <li>PNG, JPG, TIFF, HEIC</li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="camera_photo" class="upload__container">
                            <video id="video" ref="video" autoplay />
                            <div class="upload__buttons">
                                <h-button
                                    id="snap"
                                    type="button"
                                    :label="snap_button_text"
                                    :handle-click="capture"
                                    variant="primary"
                                    wide
                                    fulll_width
                                    large
                                />
                            </div>
                            <canvas id="canvas" ref="canvas" />
                        </div>

                        <div v-if="!camera_photo" class="upload__buttons">
                            <div class="upload__buttons--take">
                                <h-button
                                    label="Take Photo"
                                    variant="primary"
                                    full_width
                                    large
                                    :handle-click="openCanvas"
                                />
                            </div>

                            <div class="upload__buttons--upload">
                                <label class="upload__file-button" for="file">
                                    <span class="upload__file-button--mobile">Take Photo</span><span class="upload__file-button--desktop">Upload Photo</span>
                                </label>
                                <input
                                    id="file"
                                    ref="file"
                                    accept="image/*"
                                    type="file"
                                    @click="handleSelectPhotoClick()"
                                    @change="handleFileUpload()"
                                >
                            </div>
                        </div>
                    </div>

                    <!-- if image is uploaded-->
                    <div v-if="image">
                        <!--image canvas-->
                        <div class="upload__container">
                            <div class="id-canvas">
                                <img :src="image">
                            </div>
                            <div class="upload__buttons">
                                <div v-if="!general_error && !file_size_error" class="identity__link" @click="resetImage(upload_type)">
                                    Retake
                                </div>
                            </div>
                        </div>

                        <div v-if="file_size_error || general_error" class="upload__error">
                            <img src="@/assets/images/verification/warning.svg" alt="">
                            <p v-if="file_size_error" class="identity__body">
                                {{ file_size_error || 'File size too big. Please make sure image is under 10mb.' }}
                            </p>
                            <p v-if="general_error" class="identity__body">
                                {{ general_error || 'There was an error. Please try again.' }}
                            </p>
                        </div>

                        <h-button
                            v-if="file_size_error || general_error"
                            label="Retry"
                            variant="secondary"
                            full_width
                            large
                            :handle-click="resetImage"
                        />
                    </div>
                </div>
                <div v-if="image && !file_size_error && !general_error" class="upload__buttons">
                    <h-button
                        label="Continue"
                        :loading="is_submitting"
                        variant="primary"
                        full_width
                        large
                        :handle-click="submitFile"
                    />
                </div>
            </div>

            <div v-if="existingImage" class="upload__container">
                <div class="upload__wrapper">
                    <div class="identity__header--small">
                        <h2>Take or upload a valid ID</h2>
                        <p class="text-dim-80">
                            Used to verify your personal information is correct.
                        </p>
                    </div>

                    <!--image canvas-->
                    <div class="id-canvas">
                        <img :src="existingImage">
                    </div>
                    <div class="identity__link" data-cypress="retake" @click="resetImage('existing')">
                        Retake
                    </div>
                </div>
            </div>
        </form>
        <div v-if="existingImage" class="verification upload__buttons-container">
            <h-button
                label="Continue"
                :loading="is_submitting"
                :handle-click="continueExistingImage"
                variant="primary"
                full_width
                large
            />
        </div>
        <div v-if="!image && !existingImage" class="identity__link-container">
            <div class="identity__link" @click="handleNext">
                Cancel
            </div>
        </div>
        <CameraPermissions
            ref="cameraHandler"
            @trigger-file-input="triggerFileInput"
            @start-camera="handleStartCamera"
        />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import { analytics, analyticsActions } from '@/helpers/analytics';

import HButton from '../Shared/HButton.vue';
import CameraPermissions from './SubComponents/CameraPermissions.vue';

export default {
    name: 'UploadId',
    components: {
        HButton,
        CameraPermissions,
    },
    props: {
        handleNext: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            camera_photo: false,
            file: '',
            image: '',
            //section_type can be id_images or profile_images
            section_type: 'id_images',
            mediaStream: null,
            video: {},
            canvas: {},
            captures: [],
            base64data: null,
            is_submitting: false,
            snap_button_text: 'Snap Photo',
            existingImage: false,
            file_size_error: null,
            general_error: null,
            upload_type: null,
        };
    },
    async created() {
        await this.getUser({
            user: this.userData._id,
        });

        if (this.userData.image.id.id_url) {
            this.existingImage = this.userData.image.id.id_url;
        }
    },
    async mounted() {
        if (!this.existingImage && !this.image && !this.camera_photo) {
            this.$refs.file.addEventListener('change', this.handleFileChange);
        }
    },
    beforeDestroy() {
        if (!this.existingImage && !this.image && !this.camera_photo) {
            this.$refs.file.removeEventListener('change', this.handleFileChange);
        }
    },
    methods: {
        triggerFileInput() {
            this.$refs.file.click();
        },
        handleFileChange(event) {
            if (event.target.files.length > 0) {
                this.$refs.cameraHandler.showWebcamInstructions = false;
            }

            if (!this.existingImage && !this.image && !this.camera_photo) {
                this.$refs.file.removeEventListener('change', this.handleFileChange);
            }
        },
        continueExistingImage() {
            this.is_submitting = true;

            //go to the profile uplaod page

            if (this.$router.currentRoute.name === 'photoreupload') {
                this.$router.replace({ name: 'photoreupload', params: { section: 'profile' } });

                return;
            }

            this.handleNext();
            this.is_submitting = false;
        },
        openCanvas() {
            analytics.track(analyticsActions.identification_id_take_photo);

            this.upload_type = 'camera';
            this.$refs.cameraHandler.checkPermissions();
        },
        handleStartCamera(stream) {
            this.mediaStream = stream;
            this.startCamera();
        },
        startCamera() {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((mediaStream) => {
                        this.$refs.cameraHandler.showWebcamPrompt = false;
                        this.mediaStream = mediaStream;
                        this.camera_photo = true;

                        this.$nextTick(() => {
                            if (this.$refs.video) {
                                this.$refs.video.srcObject = mediaStream;

                                this.$refs.video.play();
                            }
                        });
                    });
            }
        },
        //user will snap a picture from the video
        capture() {
            analytics.track(analyticsActions.identification_id_snap_photo);

            let vm = this;

            this.snap_button_text = 'Say Cheese! Wait While we Take Photo';

            if (!('ImageCapture' in window)) {
                this.safariImageCapture();

                return;
            }

            //take a track of video now i.e. {kind:video, label : "FaceTime HD Camera"}
            const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
            const imageCapture = new window.ImageCapture(mediaStreamTrack);

            //snap the image via takePhoto
            return imageCapture.takePhoto().then((image) => {
                var reader = new FileReader();

                //set the read image to the img video
                reader.onload = (e) => {
                    vm.image = e.target.result;
                };

                //convert the image into base64
                reader.readAsDataURL(image);

                //set the base 64 data on completion
                reader.onloadend = function () {
                    var base64data = reader.result;

                    vm.base64data = base64data;

                    //create a new file from the base64 data
                    let newFile = vm.dataURLtoFile(vm.base64data, 'hello.png');

                    vm.file = newFile;

                    analytics.track(analyticsActions.identification_id_photo_taken, {
                        browser: 'not_safari',
                    });
                };

                this.mediaStream.getVideoTracks().forEach(function (track) {
                });

                this.snap_button_text = 'Snap Photo';
                this.stopCamera();
            });
        },

        safariImageCapture() {

            let vm = this;

            const video = document.createElement('video');
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            video.srcObject = this.mediaStream;

            return new Promise((resolve, reject) => {
                video.addEventListener('loadeddata', async () => {
                    const { videoWidth, videoHeight } = video;

                    canvas.width = videoWidth;
                    canvas.height = videoHeight;

                    try {
                        await video.play();
                        context.drawImage(video, 0, 0, videoWidth, videoHeight);

                        vm.image = canvas.toDataURL(resolve, 'image/png');

                        let newFile = vm.dataURLtoFile(vm.image, 'hello.png');

                        vm.file = newFile;

                        analytics.track(analyticsActions.identification_id_photo_taken, {
                            browser: 'safari',
                        });

                        this.stopCamera();

                    } catch (error) {
                        reject(error);
                    }
                });
            });
        },

        stopCamera() {
            if (this.mediaStream) {
                this.mediaStream.getVideoTracks().forEach(function (track) {
                    track.stop();
                });
            }
        },

        //convert base64 data to file
        dataURLtoFile(dataurl, filename) {
            //divide the url
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                //decode the base64 string
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            //complete the creation of the file
            return new File([u8arr], filename, { type: mime });
        },

        handleSelectPhotoClick() {
            analytics.track(analyticsActions.identification_id_select_photo);
            this.upload_type = 'upload';
        },
        handleFileUpload() {
            analytics.track(analyticsActions.identification_id_photo_selected);
            //when file is changed we set the local file var to the first File
            //object in the FileList on the input[type="file"]
            this.file = this.$refs.file.files[0];

            //show uploaded image
            this.createImage(this.file);
        },
        createImage(file) {
            //Image constructor creates a new HTMLImageElement
            var image = new Image();

            //FileReader object lets the app asynchronously read content of file
            var reader = new FileReader();

            //create a this instance
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;
            };

            reader.readAsDataURL(file);
        },
        resetImage(path) {
            analytics.track(analyticsActions.identification_id_retake_photo);

            this.file = '';
            this.image = '';
            this.existingImage = false;
            this.camera_photo = false;
            this.file_size_error = null;
            this.general_error = null;
            this.stopCamera();

            if (path === 'camera') {
                this.openCanvas();
            }
        },

        //function to submit file if this is a reupload from a dashboard referral
        reuploadFile() {
            //instantiate a new FormData object
            let formData = new FormData();

            //append the file to the formData
            formData.append('file', this.file);

            //append the user_id
            formData.append('client_id', this.$route.params.userId);

            //choose section type
            formData.append('image_type', this.section_type);

            //set a reload flag to true
            formData.append('reupload_image', true);

            //add a intake ID number
            if (this.$route.params.intakeId) {
                formData.append(
                    'intake',
                    this.$route.params.intakeId,
                );
            }

            //create a view model reference
            let vm = this;

            vm.is_submitting = true;

            //submit photo id
            vm.uploadProfileImage(formData)
                .then((response) => {
                    vm.is_submitting = false;
                    vm.$router.replace({ name: 'photoreupload', params: { section: 'profile' } });
                })
                .catch((err) => {
                    vm.is_submitting = false;
                });
        },
        returnVerificationIntakeId() {
            let intakeId;

            if (this.$route.name === 'verification' && this.userData.intakes && this.userData.intakes.length > 0) {
                intakeId = this.userData.intakes[0];
            } else {
                intakeId = this.intake_id;
            }

            return intakeId;
        },
        async submitFile() {
            //change so that verification just goes to the next step

            analytics.track(analyticsActions.identification_id_submitted);

            //change the state
            this.is_submitting = true;
            this.file_size_error = false;
            this.general_error = false;

            //implement a new FormData object
            let formData = new FormData();

            //default is not reupload
            let is_reupload = false;

            //exit out of function if this is a reupload from the dashboard
            if (this.$router.currentRoute.name === 'photoreupload') {
                //set reupload image to true
                return this.reuploadFile();
            }

            //append the file to the formData
            formData.append('file', this.file);

            const intakeId = this.returnVerificationIntakeId();

            if (intakeId) {
                formData.append('intake', intakeId);
            }

            //append clientId to the file
            formData.append('client_id', this.userData._id);

            //choose section type
            formData.append('image_type', this.section_type);

            //set reupload image
            formData.append('reupload_image', false);

            //create a view instance
            let vm = this;

            //submit photo id
            try {
                await this.uploadProfileImage(formData);
                this.handleNext();
                await this.$nextTick();
            } catch (error) {
                if ('message' in error && error.message === 'FileSizeLimitError') {
                    this.file_size_error = true;
                } else {
                    this.general_error = true;
                }
                console.error('Submit Photo error', error);
            } finally {
                this.is_submitting = false;
            }
        },
        ...mapMutations({
            updateUser: 'authenticate/updateUser',
            initializeQuestionnaireStore:
                'questionnaire/initializeQuestionnaireStore',
        }),
        ...mapActions({
            uploadProfileImage: 'intake/uploadProfileImage',
            getUser: 'user/retrieveUser',
        }),
    },
    computed: {
        ...mapState({
            questionnaireData: (state) => state.questionnaire.questionnaireData,
            userData: (state) => state.user.user,
            intake_id: (state) => state.intake.intake_id,
        }),
    },
};
</script>

<style lang="scss">

</style>
