<template>
    <div class="coupon-code">
        <div class="coupon-code__row">
            <HTextInput
                id="couponCode"
                ref="couponCodeInput"
                v-model="couponCode"
                name="couponCode"
                label="Discount Code"
                class="coupon-code__input"
                data-test="coupon-code__input"
                :maxlength="MAX_DISCOUNT_CODE_LENGTH"
                :error_border="!!errorMessage"
                :vuelidate="$v.couponCode"
                @input="handleInput"
                @keydown="handleKeydown"
            />
            <HButtonV2
                large
                variant="primary"
                class="coupon-code__button"
                data-test="coupon-code__apply-button"
                :loading="isLoading"
                :disabled="isLoading || !couponCode || errorMessage"
                @click="handleApplyCoupon"
            >
                Apply
            </HButtonV2>
        </div>
        <div v-if="isApplied || errorMessage" class="coupon-code__row">
            <div
                v-if="isApplied"
                class="coupon-code__message coupon-code__success"
            >
                <div class="coupon-code__checkmark">
                    <span class="coupon-code__checkmark-icon" />
                </div>
                <span>Special discount applied</span>
            </div>
            <div
                v-if="errorMessage"
                class="coupon-code__message coupon-code__error"
            >
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';

import HButtonV2 from '@/components/Shared/HButtonV2';
import HTextInput from '@/components/Shared/HTextInput';

const MAX_DISCOUNT_CODE_LENGTH = 20;

export default {
    name: 'CouponCode',
    components: {
        HButtonV2,
        HTextInput,
    },
    props: {
        products: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            couponCode: '',
            isApplied: false,
            isLoading: false,
            errorMessage: null,
            MAX_DISCOUNT_CODE_LENGTH,
        };
    },
    validations: {
        couponCode: {
            maxLength: maxLength(MAX_DISCOUNT_CODE_LENGTH),
        },
    },
    computed: {
        productIds() {
            return this.products.map(product => product.id);
        },
    },
    beforeDestroy() {
        this.setCouponDiscount({ id: 'data', value: null });
    },
    methods: {
        ...mapActions({
            applyCouponCode: 'payment/applyCouponCode',
        }),
        ...mapMutations({
            setCouponDiscount: 'payment/setCouponDiscount',
        }),
        handleInput(value) {
            const validCharacters = /^[a-zA-Z0-9]*$/;

            this.errorMessage = null;

            if (value.length > MAX_DISCOUNT_CODE_LENGTH) {
                this.errorMessage = 'Max amount of characters reached';
            } else if (!validCharacters.test(value)) {
                this.errorMessage = 'Invalid discount code';
            }

            this.couponCode = value.slice(0, MAX_DISCOUNT_CODE_LENGTH);
            this.isApplied = false;
        },
        handleKeydown(event) {
            if (event.key === 'Enter') this.handleApplyCoupon();
        },
        async handleApplyCoupon() {
            if (!this.couponCode || this.isLoading || this.errorMessage) return;

            this.isLoading = true;
            this.errorMessage = null;

            try {
                const response = await this.applyCouponCode({
                    promoCode: this.couponCode,
                    products: this.products.map((product) => ({ ...product, product: product.id })),
                });

                if (response?.productDiscounts && this.productIds.some(productId => response.productDiscounts[productId]?.discount)) {
                    this.isApplied = true;
                } else {
                    this.errorMessage = 'Discount code not applicable for selected product(s)';
                }
            } catch (error) {
                if (error.status === 404) {
                    this.errorMessage = 'Discount code not applicable for selected product(s)';
                } else {
                    this.errorMessage = 'Invalid discount code';
                }
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.coupon-code {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__row {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__input {
        width: 100%;
    }

    &__button {
        height: 100%;
        border-radius: 0.75rem;
    }

    &__message {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-family: 'Helvetica Now Var', sans-serif;
        font-size: 0.75rem;
        font-weight: 700;
    }
    &__success {
        color: $calm-green-200;
    }

    &__error {
        color: $urgent-red-200;
    }

    &__checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        min-width: 15px;
        height: 15px;
        background-color: $calm-green-200;
        border-radius: 100%;

        &-icon {
            position: relative;
            width: 8px;
            height: 4px;
            margin-top: -3px;
            border-left: 1.5px solid #fff;
            border-bottom: 1.5px solid #fff;
            transform: rotate(-50deg);
        }
    }
}
</style>
